<template>
    <cc-layer :headline="$t('general.found_creeper')">
        <h3>{{ $t('info.found_creeper') }}</h3>
        <div style="display: flex">
            <div style="flex-grow: 1">
                <table>
                    <tr>
                        <td>{{ $t('general.name') }}</td>
                        <td>{{ creeper.name }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('melee.dice') }}</td>
                        <td>{{ creeper.dice }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('melee.hitpoints') }}</td>
                        <td>{{ creeper.hitpoints }}</td>
                    </tr>
                </table>
            </div>
            <cc-creeper-battle
                :name="creeper.name"
                :image="creeper.image"
                :frequency="creeper.frequency"
            />
        </div>
        <cc-buttonbar class="mt1">
            <cc-button @click="attack()">
                {{ $t('button.attack') }}
            </cc-button>
        </cc-buttonbar>
    </cc-layer>
</template>

<script>
import Button from '../../component/Button.vue';
import Buttonbar from '../../component/Buttonbar.vue';
import Layer from '../../component/Layer.vue';
import CreeperBattle from '../../component/CreeperBattle.vue';
import GameEventRepository from "../../repository/GameEventRepository";
import UserRepository from "../../repository/UserRepository";

export default {
    components: {
        'cc-button': Button,
        'cc-buttonbar': Buttonbar,
        'cc-creeper-battle': CreeperBattle,
        'cc-layer': Layer,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        creeper: {
            required: true,
            type: Object,
        },
        meleeId: {
            required: true,
            type: String,
        },
    },
    methods: {
        attack() {
            GameEventRepository
                .remove(this.id)
                .then(() => {
                    UserRepository.findMe();
                    this.$store.commit('removeLayer', this);
                    this.$router.push({ name: 'fight', params: { id: this.meleeId } });
                });
        },
    },
}
</script>
