<template>
    <div class="dice">
        <div
            v-for="die in availableDice"
            :key="die.id"
        >
            <cc-die
                :id="'dice_' + die.id"
                :ref="'dice_' + die.id"
                :face="die.face"
                :highlight="die.id === selectedDiceId"
                :visible="!die.used"
                :clickable="userOnTurn && !die.burning && !die.locked"
                :blind="die.blind"
                @click.native="selectDice(die)"
                @dropped="$emit('dropped')"
            />
            <div
                v-if="!die.used"
                class="status"
            >
                <div v-if="die.frozen">
                    {{ $t('dice.status_effect.frozen') }}
                </div>
                <div v-if="die.burning">
                    {{ $t('dice.status_effect.burning') }}
                </div>
                <div v-if="die.blind">
                    {{ $t('dice.status_effect.blind') }}
                </div>
                <div v-if="die.locked">
                    {{ $t('dice.status_effect.locked') }}
                </div>
                <a
                    v-if="die.burning && userOnTurn"
                    class="action"
                    @click="extinguish(die)"
                >
                    {{ $t('dice.extinguish') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>

import Die from "../Die";
import MeleeRepository from "../../repository/MeleeRepository";

export default {
    components: {
        'cc-die': Die,
    },
    props: {
        dice: {
            type: Array,
            required: true,
        },
        userOnTurn: {
            type: Boolean,
            required: true,
        },
        selectedDiceId: {
            type: String,
            required: false,
            default: null,
        },
        diceOfPreviousTurns: {
            type: Array,
            required: true,
        },
    },
    computed: {
        availableDice() {
            return this.dice.filter(die => this.diceOfPreviousTurns.indexOf(die.id) === -1);
        },
    },
    created() {
        window.eventbus.$on('move_dice', event => this.$refs['dice_' + event.id][0].moveTo(event.x, event.y));
    },
    methods: {
        selectDice(dice) {
            if (!this.userOnTurn) {
                return;
            }

            if (dice.burning) {
                return;
            }

            if (dice.locked) {
                return;
            }

            this.$emit('select-dice', dice);
        },
        extinguish(dice) {
            MeleeRepository.extinguishDice(this.id, dice.id).then(() => this.$emit('extinguished'));
        },
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../../css/variables";

.dice {
    .status {
        text-align: center;
        .action {
            color: $color-text-dark;
        }
    }
}
</style>
