export default class LevelupLayer {
    constructor(id, level, gold, experience, limitpoints, features) {
        this.id = id;
        this.type = 'levelup';
        this.level = level;
        this.gold = gold;
        this.experience = experience;
        this.limitpoints = limitpoints;
        this.features = features;
    }
}
