import DiamondRepository from "../../../repository/DiamondRepository";

export default class BuyMeleeLayer {
    constructor() {
        this.type = 'buy';
        this.headline = 'melee_refill.headline';
        this.message = 'melee_refill.description';
        this.callback = () => DiamondRepository.refill('melee');
    }
}
