export default class Payment {
    buy(userId, productId, baseUrl) {
        window.location.href = baseUrl + '?project=cstlcr&amount=' + this.getAmount(productId) + '&currency=EUR&title=' + productId + '&paytext=' + window.vue.$t('payment.product.' + productId) + '&user-id=' + userId + '&testmode=0';
    }

    getAmount(productId) {
        switch (productId) {
            case 'diamonds30':
                return 299;

            case 'diamonds150':
                return 999;

            case 'diamonds500':
                return 2499;

            case 'starterpackage':
                return 299;
        }

        throw new Error('unknown product id "' + productId + '"');
    }
}
