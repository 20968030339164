export default class Coordinate {

    constructor(x, y) {
        this.x = x;
        this.y = y;
    }

    add(coordinate) {
        return new Coordinate(this.x + coordinate.x, this.y + coordinate.y);
    }

    distanceTo(coordinate) {
        return Math.sqrt(Math.pow(this.x - coordinate.x, 2) + Math.pow(this.y - coordinate.y, 2));
    }
}
