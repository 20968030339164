import axios from 'axios';

export default class InventoryRepository {

    static list() {
        return new Promise(resolve => {
            axios
                .get('/inventory')
                .then(response => resolve(response.data.data));
        });
    }

    static toggle(id) {
        return new Promise(resolve => {
            axios
                .patch('/inventory/' + id)
                .then(() => resolve());
        });
    }

    static upgrade(id) {
        return new Promise(resolve => {
            axios
                .patch('/inventory/' + id + '/level')
                .then(() => resolve());
        });
    }
}
