import axios from 'axios';

export default class CreeperRepository {
    static list() {
        return new Promise((resolve) => {
            axios
                .get('/creepers')
                .then(response => resolve(response.data.data));
        });
    }
}
