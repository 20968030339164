import Coordinate from "../Coordinate";

export default class RiverDrawer {

    constructor(context) {
        this.context = context;
    }

    draw(river) {
        const coordinates = river.line;
        this.context.beginPath();
        let element = 0;

        coordinates.forEach(coordinate => {
            const point = coordinate;
            const previous = element > 0 ? coordinates[element - 1] : coordinate;
            const next = element < coordinates.length - 1 ? coordinates[element + 1] : coordinate;
            const angle = Math.atan2(previous.y - next.y, previous.x - next.y);
            const offset = Math.atan(element * river.width / 500 / 3) * river.increment;
            this.context.moveTo(new Coordinate(
                point.x - Math.sin(angle) * offset,
                point.y + Math.cos(angle) * offset,
            ));
            this.context.lineTo(new Coordinate(
                point.x + Math.sin(angle) * offset,
                point.y - Math.cos(angle) * offset,
            ));
            element++;
        });
        this.context.stroke('rgb(31, 120, 180)');
        this.context.closePath();
    }
}
