<template>
    <div class="frame_small">
        <h2
            v-if="headline"
            class="frame_headline"
        >
            {{ headline }}
        </h2>
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        headline: {
            required: false,
            type: String,
            default: null,
        }
    }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.frame_small {
    border: 28px solid transparent;
    border-image: url("../../images/frame_small.png") 28 repeat;
    background: url("../../images/wooden_background.jpg");
    background-size: cover;
    background-origin: border-box;
    color: $color-text-light;
    margin: 16px 0 48px;
    position: relative;
    h2.frame_headline {
        background-image: url("../../images/headline.png");
        width: 220px;
        height: 42px;
        text-align: center;
        margin: -44px auto 0;
        font-size: 15px;
        text-transform: uppercase;
        padding-top: 10px;
    }
}
</style>
