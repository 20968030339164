<template>
    <cc-frame-big :headline="$t('diamond')">
        <div class="payment_items">
            <cc-diamond
                id="diamonds30"
                price="2,99"
                quantity="30"
            />
            <cc-diamond
                id="diamonds150"
                price="9,99"
                quantity="150"
            />
            <cc-diamond
                id="diamonds500"
                price="24,99"
                quantity="500"
            />
        </div>
    </cc-frame-big>
</template>

<script>
import Diamond from '../component/Diamond.vue';
import FrameBig from "../component/FrameBig";

export default {
    components: {
        'cc-frame-big': FrameBig,
        'cc-diamond': Diamond,
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.payment_items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
</style>
