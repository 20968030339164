<template>
    <cc-frame-big :headline="$t('payment_method')">
        <div class="payment_methods">
            <cc-payment-method
                :product-id="id"
                image-group="hp"
                base-url="https://mobilepayment.micropayment.de/handypay/event/"
            />
            <cc-payment-method
                :product-id="id"
                image-group="dbt"
                base-url="https://sepadirectdebit.micropayment.de/lastschrift/event/"
            />
        </div>
    </cc-frame-big>
</template>

<script>
import PaymentMethod from '../component/PaymentMethod.vue';
import FrameBig from "../component/FrameBig";

export default {
    components: {
        'cc-frame-big': FrameBig,
        'cc-payment-method': PaymentMethod,
    },
    props: {
        id: {
            required: true,
            type: String,
        },
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.payment_methods {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
</style>
