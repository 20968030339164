<template>
    <cc-premium
        type="base"
        :features="4"
        :days-left="user.premiumBaseLeft"
        :level="6"
    />
</template>

<script>
import Premium from '../../component/Premium.vue';

export default {
    components: {
        'cc-premium': Premium,
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    },
}
</script>
