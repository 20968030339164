<template>
    <cc-side-box
        :headline="$t('payment.product.starterpackage')"
        :note="$t('starter_package.limit')"
    >
        <p>{{ $t('payment.buy_starter_package', { amount: 150, price: 2.99 }) }}</p>
        <cc-buttonbar>
            <cc-button @click="payment.buy('starterpackage')">
                {{ $t('button.buy') }}
            </cc-button>
        </cc-buttonbar>
    </cc-side-box>
</template>

<script>
import Payment from "../model/Payment";

import Button from './Button.vue';
import Buttonbar from './Buttonbar.vue';
import SideBox from './SideBox.vue';

export default {
    components: {
        'cc-button': Button,
        'cc-buttonbar': Buttonbar,
        'cc-side-box': SideBox,
    },
    data() {
        return {
            payment: new Payment(),
        }
    },
}
</script>
