import axios from 'axios';

import Vue from 'vue';
import VueAxios from 'vue-axios';
import authentication from "./authentication";

Vue.use(VueAxios, axios);

axios.defaults.baseURL = API_URL;
authentication.updateAxios();

axios.interceptors.response.use(response => {
    if (response.data.meta) {
        const gameEvent = response.data.meta['game_event'];
        if (gameEvent) {
            window.eventbus.$emit('game_event', gameEvent);
        }
    }

    return response;
}, error => {
    if (error.response) {
        if (error.response.status === 401) {
            window.eventbus.$emit('api_returned_401');
        }
        if (error.response.status === 403) {
            window.eventbus.$emit('api_returned_403');
        }
        if (error.response.data.errors) {
            window.eventbus.$emit('errors', error.response.data.errors);
        }
    }

    return Promise.reject(error);
});

export default axios;
