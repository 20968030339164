import Coordinate from "../Coordinate";
import Burg from "../Burg";

export default class BurgParser {

    parse(data) {
        return data.map(burg => {
            return new Burg(
                burg.Burg,
                new Coordinate(burg.Longitude, -burg.Latitude),
            );
        });
    }
}
