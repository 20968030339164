<template>
    <input
        :type="type"
        :value="value"
        @input="$emit('input', $event.target.value)"
    />
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: false,
            default: 'text',
        },
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

input[type="text"], input[type="number"], input[type="password"] {
    border: none;
    background: transparent url("../../images/input.png");
    width: 235px;
    height: 38px;
    color: $color-text-light;
    text-align: right;
    padding: 0 24px;
    font-size: 14px;
    outline: none;
}
</style>
