<template>
    <cc-frame-big :headline="$t('menu.melee')">
        <cc-loading v-if="loading" />
        <div v-else>
            <div
                class="fight"
                :class="{ left: melee.actor.id === melee.attacker.id, right: melee.actor.id === melee.defender.id }"
            >
                <cc-fighter
                    side="left"
                    :fighter="melee.attacker"
                    :on-turn="melee.actor.id === melee.attacker.id && !melee.isOver"
                    @end-turn="endTurn()"
                />
                <div class="playground">
                    <div class="cards">
                        <cc-card
                            v-for="card in melee.actor.deck"
                            :id="'card_' + card.id"
                            :ref="'card_' + card.id"
                            :key="card.id"
                            :name="card.cardDefinition.name"
                            :usages="card.usages"
                            :slots="card.slots"
                            :dice="card.cardDefinition.dice"
                            :hitpoints="card.cardDefinition.hitpoints"
                            :types="card.cardDefinition.types"
                            :selected-dice="selectedDiceFace"
                            :usable="card.usable"
                            :shocked="card.shocked"
                            @accept="accepted($event, card)"
                            @unshock="unshocked(card)"
                        />
                    </div>
                    <cc-dice
                        :dice="melee.actor.dice"
                        :user-on-turn="melee.actor.id === melee.attacker.id"
                        :selected-dice-id="selectedDiceId"
                        :dice-of-previous-turns="diceOfPreviousTurns"
                        @select-dice="selectDice($event)"
                        @extinguished="update(false)"
                        @dropped="dropped()"
                    />
                </div>
                <cc-fighter
                    side="right"
                    :fighter="melee.defender"
                    :on-turn="melee.actor.id !== melee.attacker.id && !melee.isOver"
                    @end-turn="endTurn()"
                />
            </div>
            <div
                v-if="melee.isOver"
                class="winner"
            >
                <div>{{ $t('general.winner') }}</div>
                <div class="name">
                    {{ melee.winner.username }}
                </div>
                <div
                    v-if="melee.winner.id === melee.attacker.id && melee.type !== 'creeper'"
                    class="reward"
                >
                    <cc-icon
                        type="gold"
                        :description="$t('gold')"
                    >
                        {{ melee.gold }}
                    </cc-icon>
                    <cc-icon
                        v-if="melee.type === 'ranking'"
                        type="ranking"
                        :description="$t('stats.ranking')"
                    >
                        {{ melee.attacker.ranking }}.
                    </cc-icon>
                </div>
            </div>
        </div>
    </cc-frame-big>
</template>

<script>
import MeleeRepository from "../repository/MeleeRepository";

import Card from '../component/Card.vue';
import Icon from '../component/Icon.vue';
import Loading from '../component/Loading.vue';
import FrameBig from "../component/FrameBig";
import Fighter from "../component/fight/Fighter";
import Dice from "../component/fight/Dice";

export default {
    components: {
        'cc-frame-big': FrameBig,
        'cc-card': Card,
        'cc-icon': Icon,
        'cc-loading': Loading,
        'cc-fighter': Fighter,
        'cc-dice': Dice,
    },
    props: {
        id: {
            required: true,
            type: String,
        }
    },
    data() {
        return {
            loading: true,
            melee: {},
            selectedDiceId: null,
            meleeUpdate: null,
            diceOfPreviousTurns: [],
        }
    },
    computed: {
        selectedDiceFace() {
            if (this.selectedDiceId === null) {
                return null;
            }

            for (let i = 0; i < this.melee.actor.dice.length; i++) {
                if (this.melee.actor.dice[i].id === this.selectedDiceId) {
                    return this.melee.actor.dice[i].face;
                }
            }

            throw new Error('Could not find dice ' + this.selectedDiceId);
        },
    },
    created() {
        MeleeRepository.find(this.id).then(melee => {
            this.melee = melee;
            this.loading = false;
        });
    },
    methods: {
        endTurn() {
            MeleeRepository.createTurn(this.id).then(() => this.update());
            this.selectedDiceId = null;
        },
        selectDice(dice) {
            if (this.melee.isOver) {
                return;
            }

            if (this.selectedDiceId === dice.id) {
                this.selectedDiceId = null;
            } else {
                this.selectedDiceId = dice.id;
            }
        },
        accepted(slot, card) {
            MeleeRepository.createAction(this.id, card.id, [this.selectedDiceId]).then(() => this.update());
            this.selectedDiceId = null;
        },
        unshocked(card) {
            MeleeRepository.unshockCard(this.id, card.id, this.selectedDiceId).then(() => this.update());
            this.selectedDiceId = null;
        },
        update(markDiceAsOfPreviousTurns = true) {
            MeleeRepository.find(this.id).then(melee => {
                const slot = document.querySelector('#card_' + melee.lastAction.cardId + ' .slot');

                const lastAction = melee.lastAction;
                if (lastAction.diceIds) {
                    for (let i = 0; i < lastAction.diceIds.length; i++) {
                        const dice = document.querySelector('#dice_' + lastAction.diceIds[i]);
                        const dicePosition = {
                            x: dice.getBoundingClientRect().left + dice.clientWidth / 2,
                            y: dice.getBoundingClientRect().top + dice.clientHeight / 2,
                        };
                        const slotPosition = {
                            x: slot.getBoundingClientRect().left + slot.clientWidth / 2,
                            y: slot.getBoundingClientRect().top + slot.clientHeight / 2,
                        };
                        const x = -(dicePosition.x - slotPosition.x);
                        const y = -(dicePosition.y - slotPosition.y);

                        window.eventbus.$emit('move_dice', { id: lastAction.diceIds[i], x, y });
                    }

                    this.meleeUpdate = melee;
                } else {
                    if (markDiceAsOfPreviousTurns) {
                        this.melee.actor.dice.forEach(die => this.diceOfPreviousTurns.push(die.id));
                    }
                    this.melee = melee;
                }
            });
        },
        dropped() {
            this.melee = this.meleeUpdate;
        },
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.fight {
    display: flex;
    justify-content: space-between;
    &.left {
        .dice, .cards {
            justify-content: flex-start;
        }
        .dice {
            order: 1;
        }
        .cards {
            order: 2;
        }
    }
    &.right {
        .dice, .cards {
            justify-content: flex-end;
        }
        .cards {
            order: 1;
        }
        .dice {
            order: 2;
        }
    }
    .playground {
        display: flex;
        flex-grow: 1;
    }
    .cards {
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        .card {
            margin: 0 8px 16px;
        }
    }
}
.winner {
    position: absolute;
    left: 0;
    top: 216px;
    width: 100%;
    text-align: center;
    .name {
        font-size: 28px;
        font-weight: bold;
    }
    .reward {
        display: flex;
        justify-content: center;
    }
}
</style>
