<template>
    <div>
        <cc-loading v-if="loading" />
        <div v-else>
            <div class="subcontent">
                <div class="maximum">
                    {{ $t('magic_treasure.maximum', { 'current': user.overallMagicTreasures, 'max': user.level*2-6 }) }}
                </div>
                <table>
                    <tr
                        v-for="(treasure, index) in availableTreasures"
                        :key="index"
                    >
                        <td>{{ $t('magic_treasure.level', { 'level': treasure.title }) }}</td>
                        <td>{{ $t('magic_treasure.costs', { 'costs': treasure.actualPrice }) }}</td>
                        <td>{{ $t('magic_treasure.income', { 'income': treasure.output }) }}</td>
                        <td>{{ $t('magic_treasure.amount', { 'amount': magicTreasure(user, treasure) }) }}</td>
                        <td>
                            <cc-button @click="sign(treasure)">
                                {{ $t('magic_treasure.sign') }}
                            </cc-button>
                        </td>
                        <td>
                            <cc-button
                                v-if="magicTreasure(user, treasure) > 0"
                                @click="dismiss(treasure)"
                            >
                                {{ $t('magic_treasure.dismiss') }}
                            </cc-button>
                        </td>
                    </tr>
                </table>
                <cc-disabled :level="8" />
            </div>
        </div>
    </div>
</template>

<script>
import TreasureRepository from "../../repository/TreasureRepository";
import UserRepository from "../../repository/UserRepository";

import Button from '../../component/Button.vue';
import Disabled from '../../component/Disabled.vue';
import Loading from '../../component/Loading.vue';

export default {
    components: {
        'cc-button': Button,
        'cc-disabled': Disabled,
        'cc-loading': Loading,
    },
    data() {
        return {
            treasures: [],
            loading: true,
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        availableTreasures() {
            return this.treasures.filter(treasure => this.user.level >= treasure.level);
        }
    },
    created() {
        TreasureRepository.list().then(treasures => {
            this.treasures = treasures;
            this.loading = false;
        });
    },
    methods: {
        sign(treasure) {
            TreasureRepository.sign(treasure.id).then(() => {
                UserRepository.findMe().then(user => this.$store.commit('loadUser', user));
                TreasureRepository.list().then(treasures => this.treasures = treasures);
            });
        },
        dismiss(treasure) {
            TreasureRepository.dismiss(treasure.id).then(() => {
                UserRepository.findMe().then(user => this.$store.commit('loadUser', user));
                TreasureRepository.list().then(treasures => this.treasures = treasures);
            });
        },
        magicTreasure(user, treasure) {
            for (let i = 0; i < user.treasures.length; i++) {
                if (treasure.id === user.treasures[i].treasure.id) {
                    return user.treasures[i].amount;
                }
            }

            return 0;
        },
    },
}
</script>
