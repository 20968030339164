export default class Biome {

    constructor(biome) {
        this.biome = biome;
    }

    getColor() {
        switch (this.biome) {
            case 'marine': return 'rgb(31, 120, 180)';
            case 'hot_desert': return 'rgb(254, 239, 124)';
            case 'cold_desert': return 'rgb(244, 228, 104)';
            case 'savanna': return 'rgb(253, 191, 111)';
            case 'grassland': return 'rgb(178, 223, 138)';
            case 'tropical_seasonal_forest': return 'rgb(31, 114, 13)';
            case 'temperate_deciduous_forest': return 'rgb(51, 160, 44)';
            case 'tropical_rainforest': return 'rgb(44, 156, 102)';
            case 'temperate_rainforest': return 'rgb(81, 155, 119)';
            case 'taiga': return 'rgb(203, 227, 81)';
            case 'tundra': return 'rgb(210, 220, 60)';
            case 'glacier': return 'rgb(247, 252, 255)';
            case 'wetland': return 'rgb(135, 143, 97)';
        }

        throw new Error('unknown biome: ' + this.biome);
    }

    getBorderColor() {
        switch (this.biome) {
            case 'marine': return 'hsl(204, 71%, 21%)';
            case 'hot_desert': return 'hsl(53, 98%, 54%)';
            case 'cold_desert': return 'hsl(53, 86%, 48%)';
            case 'savanna': return 'hsl(34, 97%, 51%)';
            case 'grassland': return 'hsl(92, 57%, 51%)';
            case 'tropical_seasonal_forest': return 'hsl(109, 80%, 5%)';
            case 'temperate_deciduous_forest': return 'hsl(116, 57%, 60%)';
            case 'tropical_rainforest': return 'hsl(151, 56%, 19%)';
            case 'temperate_rainforest': return 'hsl(151, 31%, 26%)';
            case 'taiga': return 'hsl(70, 72%, 40%)';
            case 'tundra': return 'hsl(64, 70%, 35%)';
            case 'glacier': return 'hsl(202, 100%, 78%)';
            case 'wetland': return 'hsl(70, 19%, 27%)';
        }

        throw new Error('unknown biome: ' + this.biome);
    }

    getName() {
        switch (this.biome) {
            case 'marine': return 'Marine';
            case 'hot_desert': return 'Hot Desert';
            case 'cold_desert': return 'Cold Desert';
            case 'savanna': return 'Savanna';
            case 'grassland': return 'Grassland';
            case 'tropical_seasonal_forest': return 'Tropical Seasonal Forest';
            case 'temperate_deciduous_forest': return 'Temperate Deciduous Forest';
            case 'tropical_rainforest': return 'Tropical Rainforest';
            case 'temperate_rainforest': return 'Temperate Rainforest';
            case 'taiga': return 'Taiga';
            case 'tundra': return 'Tundra';
            case 'glacier': return 'Glacier';
            case 'wetland': return 'Wetland';
        }

        throw new Error('unknown biome: ' + this.biome);
    }
}
