import axios from 'axios';

export default class ChaseRepository {
    static start(id) {
        return new Promise((resolve) => {
            axios.post('/chases/' + id).then(() => resolve());
        });
    }

    static list() {
        return new Promise((resolve) => {
            axios
                .get('/chases')
                .then(response => resolve(response.data.data));
        });
    }
}
