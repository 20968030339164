<template>
    <div
        v-if="userLevel < level"
        class="disabled"
    >
        <div>
            {{ $t('minimum_level', { 'level': level }) }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        level: {
            required: true,
            type: Number,
        }
    },
    computed: {
        userLevel() {
            return this.$store.state.user.level;
        }
    }
}
</script>

<style rel="stylesheet/scss" lang="scss">
@import "../../css/variables";

.disabled {
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.8;
    position: absolute;
    left: 0;
    top: 0;
    color: $color-text-light;
    div {
        text-align: center;
        padding-top: 14px;
        font-weight: bold;
    }
}
// @todo remove this special case and make this component's styles scoped
.subcontent {
    position: relative;
}
</style>
