<template>
    <div class="dynamic_number">
        <transition-group
            name="plus"
            tag="div"
            @after-enter="clear"
        >
            <span
                v-for="change in changesPositive"
                :key="change.id"
                class="plus"
            >
                {{ change.value }}
            </span>
        </transition-group>
        <transition-group
            name="minus"
            tag="div"
            @after-enter="clear"
        >
            <span
                v-for="change in changesNegative"
                :key="change.id"
                class="minus"
            >
                {{ change.value }}
            </span>
        </transition-group>
        <div>{{ value }}</div>
    </div>
</template>

<script>
import uuid1 from 'uuid/v1';

export default {
    props: {
        value: {
            required: true,
            type: Number,
        },
        inverted: {
            required: false,
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            changesPositive: [],
            changesNegative: [],
        }
    },
    watch: {
        value(newValue, oldValue) {
            let diff = newValue - oldValue;
            if (this.inverted) {
                diff *= -1;
            }
            if (diff > 0) {
                this.changesPositive.push({ id: uuid1(), value: '+' + diff, expires: Math.floor(Date.now() / 1000) + 5 });
            }
            if (diff < 0) {
                this.changesNegative.push({ id: uuid1(), value: diff, expires: Math.floor(Date.now() / 1000) + 5 });
            }
        }
    },
    methods: {
        clear() {
            this.changesPositive = this.changesPositive.filter(change => change.expires > Math.floor(Date.now() / 1000));
            this.changesNegative = this.changesNegative.filter(change => change.expires > Math.floor(Date.now() / 1000));
        }
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.dynamic_number {
    position: relative;
    display: inline-block;
    .plus, .minus {
        display: inline-block;
        opacity: 0;
        position: absolute;
        left: 0;
        width: 100%;
        transform: translateY(-50px);
        text-align: center;
        font-size: 0.8em;
    }
    .plus {
        color: $color-plus;
    }
    .minus {
        color: $color-minus;
    }
    .plus-enter-active, .minus-enter-active {
        transition: all 3s ease-out;
    }
    .plus-enter, .minus-enter {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>
