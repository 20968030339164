<template>
    <div>
        <cc-loading v-if="loading" />
        <div v-else>
            <div class="subcontent">
                <cc-button
                    class="prev"
                    @click="changeGoldRankingOffset(-10)"
                >
                    {{ $t('button.pagination_next') }}
                </cc-button>
                <cc-button
                    class="next"
                    @click="changeGoldRankingOffset(10)"
                >
                    {{ $t('button.pagination_previous') }}
                </cc-button>
                <table>
                    <caption class="mb1">
                        {{ $t('ranking.gold.description') }}
                    </caption>
                    <tbody>
                        <tr
                            v-for="(user, index) in goldRanking"
                            :key="index"
                        >
                            <td class="right">
                                {{ user.ranking }}.
                            </td>
                            <td>
                                <cc-avatar-portrait
                                    :gender="user.avatarGender"
                                    :type="user.avatarType"
                                />
                            </td>
                            <td>
                                <div class="username">
                                    <router-link
                                        :to="{ name: 'profile', params: { id: user.id }}"
                                        class="user"
                                    >
                                        {{ user.username }}
                                    </router-link>
                                </div>
                                <div class="level">
                                    {{ $t('general.level') }} {{ user.level }}
                                </div>
                            </td>
                            <td class="right">
                                <cc-button
                                    v-if="$store.state.user.id !== user.id"
                                    @click="$router.push({ name: 'matchup', params: { type: 'gold', id: user.id } })"
                                >
                                    {{ $t('button.attack') }}
                                </cc-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <cc-disabled :level="5" />
            </div>
        </div>
    </div>
</template>

<script>
import AvatarPortrait from '../../component/AvatarPortrait.vue';
import Button from '../../component/Button.vue';
import Disabled from '../../component/Disabled.vue';
import Loading from '../../component/Loading.vue';

import UserRepository from "../../repository/UserRepository";

export default {
    components: {
        'cc-avatar-portrait': AvatarPortrait,
        'cc-button': Button,
        'cc-disabled': Disabled,
        'cc-loading': Loading,
    },
    data() {
        return {
            goldRanking: [],
            goldRankingOffset: Math.floor(this.$store.state.user.ranking / 10) * 10,
            loading: true,
        }
    },
    created() {
        this.fetchGoldRanking();
    },
    methods: {
        changeGoldRankingOffset(diff) {
            this.goldRankingOffset += diff;
            this.fetchGoldRanking();
        },
        fetchGoldRanking() {
            UserRepository.findGoldRanking(this.goldRankingOffset).then(ranking => {
                this.goldRanking = ranking;
                this.loading = false;
            });
        }
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../../css/variables";

.prev {
    float: left;
    margin-left: 86px;
}
.next {
    float: left;
}
table {
    tr:not(:last-of-type) {
        td {
            border-bottom: 1px solid $color-line-seperator;
        }
    }
}
.avatar {
    margin: 0 auto;
}
.username {
    font-size: 21px;
}
.level {
    font-size: 13px;
}
</style>
