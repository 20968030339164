<template>
    <cc-layer :headline="creeper.name">
        <div style="display: flex">
            <div style="flex-grow: 1">
                <table>
                    <tr>
                        <td>{{ $t('general.name') }}</td>
                        <td>{{ creeper.name }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('general.frequency') }}</td>
                        <td>{{ $t('frequency.' + creeper.frequency) }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('melee.dice') }}</td>
                        <td>{{ creeper.dice }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('melee.hitpoints') }}</td>
                        <td>{{ creeper.hitpoints }}</td>
                    </tr>
                </table>
            </div>
            <cc-creeper-battle
                :name="creeper.name"
                :image="creeper.image"
                :frequency="creeper.frequency"
            />
        </div>
        <cc-buttonbar class="mt1">
            <cc-button
                stop
                @click="close()"
            >
                {{ $t('button.close') }}
            </cc-button>
        </cc-buttonbar>
    </cc-layer>
</template>

<script>
import Button from '../../component/Button.vue';
import Buttonbar from '../../component/Buttonbar.vue';
import Layer from '../../component/Layer.vue';
import CreeperBattle from '../../component/CreeperBattle.vue';

export default {
    components: {
        'cc-button': Button,
        'cc-buttonbar': Buttonbar,
        'cc-creeper-battle': CreeperBattle,
        'cc-layer': Layer,
    },
    props: {
        creeper: {
            required: true,
            type: Object,
        },
    },
    methods: {
        close() {
            this.$store.commit('removeLayer', this);
        },
    },
}
</script>
