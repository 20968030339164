import axios from 'axios';

export default class FriendRepository {
    static findAll() {
        return new Promise((resolve, reject) => {
            axios
                .get('/friends')
                .then(response => {
                    if (!response.data.data) {
                        reject();
                    }

                    resolve(response.data.data);
                });
        });
    }
}
