<template>
    <v-app>
        <cc-navigation-drawer
            v-if="$store.getters.isAuthenticated"
            v-model="showNavigation"
        >
            <cc-main-menu />
        </cc-navigation-drawer>
        <cc-app-bar v-if="$store.getters.isAuthenticated" />
        <v-main
            class="mt-5"
            :style="contentStyles"
        >
            <v-container>
                <router-view />
            </v-container>
        </v-main>
        <cc-layers />
    </v-app>
</template>

<script>
import ErrorLayer from "../model/dto/layer/ErrorLayer";
import LevelupLayer from "../model/dto/layer/LevelupLayer";
import ChaseLayer from "../model/dto/layer/ChaseLayer";
import FoundCreeperLayer from "../model/dto/layer/FoundCreeperLayer";

import Layers from './Layers.vue';
import MainMenu from './MainMenu.vue';
import AppBar from "./AppBar";
import NavigationDrawer from "./NavigationDrawer";

export default {
    components: {
        'cc-layers': Layers,
        'cc-main-menu': MainMenu,
        'cc-app-bar': AppBar,
        'cc-navigation-drawer': NavigationDrawer,
    },
    data() {
        return {
            showNavigation: null,
        }
    },
    computed: {
        contentStyles() {
            return {
                marginLeft: (this.isMobile ? 0 : 20) + 'px',
            }
        },
        isMobile() {
            return this.$vuetify.breakpoint.width < 1264;
        },
    },
    created() {
        window.eventbus.$on('errors', errors => {
            this.$store.commit('addLayer', new ErrorLayer(errors));
        });

        window.eventbus.$on('game_event', gameEvent => {
            if (gameEvent.name === 'found_creeper') {
                this.$store.commit('addLayer', new FoundCreeperLayer(gameEvent.id, gameEvent.creeper, gameEvent.melee_id));
            }

            if (gameEvent.name === 'chase_successful') {
                this.$store.commit('addLayer', new ChaseLayer(gameEvent.id, gameEvent.creeper_name, gameEvent.creeper_image, gameEvent.creeper_frequency, gameEvent.card_definition));
            }

            if (gameEvent.name === 'level_up') {
                this.$store.commit('addLayer', new LevelupLayer(gameEvent.id, gameEvent.level, gameEvent.gold, gameEvent.experience, gameEvent.limitpoints, gameEvent.features));
            }
        });
    }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";
</style>
