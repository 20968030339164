import DiamondRepository from "../../../repository/DiamondRepository";

export default class BuyEnergyLayer {
    constructor() {
        this.type = 'buy';
        this.headline = 'energy_refill.headline';
        this.message = 'energy_refill.description';
        this.callback = () => DiamondRepository.refill('energy');
    }
}
