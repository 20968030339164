import Coordinate from "../Coordinate";
import River from "../River";

export default class RiverParser {

    parse(json) {
        return json.features.map(feature => {
            return new River(
                feature.properties.width,
                feature.properties.increment,
                feature.geometry.coordinates.map(coordinate => new Coordinate(coordinate[0], -coordinate[1])),
            );
        });
    }
}
