<template>
    <div class="layer">
        <cc-frame-big :headline="headline">
            <slot />
        </cc-frame-big>
    </div>
</template>

<script>
import FrameBig from '../component/FrameBig.vue';

export default {
    components: {
        'cc-frame-big': FrameBig,
    },
    props: {
        headline: {
            type: String,
            required: true,
        }
    }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.layer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: $color-text-light;
}
</style>
