<template>
    <cc-layer :headline="$t('general.task')">
        <h3>{{ $t('info.task_finished') }}</h3>
        <div>{{ $t('info.reward') }}</div>
        <div>
            <cc-icon
                v-if="gold > 0"
                type="gold"
                :description="$t('gold')"
            >
                {{ gold }}
            </cc-icon>
            <cc-icon
                v-if="experience > 0"
                type="experience"
                :description="$t('experience')"
            >
                {{ experience }}
            </cc-icon>
            <cc-icon
                v-if="diamonds > 0"
                type="diamond"
                :description="$t('diamond')"
            >
                {{ diamonds }}
            </cc-icon>
        </div>
        <cc-buttonbar class="mt1">
            <cc-button @click="close()">
                {{ $t('button.ok') }}
            </cc-button>
        </cc-buttonbar>
    </cc-layer>
</template>

<script>
import Button from '../../component/Button.vue';
import Buttonbar from '../../component/Buttonbar.vue';
import Icon from '../../component/Icon.vue';
import Layer from '../../component/Layer.vue';

export default {
    components: {
        'cc-button': Button,
        'cc-buttonbar': Buttonbar,
        'cc-icon': Icon,
        'cc-layer': Layer,
    },
    props: {
        gold: {
            type: Number,
            required: true,
        },
        experience: {
            type: Number,
            required: true,
        },
        diamonds: {
            type: Number,
            required: true,
        },
    },
    methods: {
        close() {
            this.$store.commit('removeLayer', this);
        }
    }
}
</script>
