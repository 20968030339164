export default class ChaseLayer {
    constructor(id, creeperName, creeperImage, creeperFrequency, cardDefinition) {
        this.id = id;
        this.type = 'chase';
        this.creeperName = creeperName;
        this.creeperImage = creeperImage;
        this.creeperFrequency = creeperFrequency;
        this.cardDefinition = cardDefinition;
    }
}
