<template>
    <cc-layer :headline="$t('general.error')">
        <ul>
            <li
                v-for="(message, index) in messages"
                :key="index"
            >
                {{ message }}
            </li>
        </ul>
        <cc-button @click="close()">
            {{ $t('button.ok') }}
        </cc-button>
    </cc-layer>
</template>

<script>
import Button from '../../component/Button.vue';
import Layer from '../../component/Layer.vue';

export default {
    components: {
        'cc-button': Button,
        'cc-layer': Layer,
    },
    props: {
        messages: {
            required: true,
            type: Array,
        }
    },
    methods: {
        close() {
            this.$store.commit('removeLayer', this);
        }
    }
}
</script>
