<template>
    <div
        class="icon_container"
        :style="containerStyles"
    >
        <div
            class="icon"
            :class="'icon_' + type"
            :style="iconStyles"
        />
        <div
            class="text"
            :style="textStyles"
        >
            <span
                class="value"
                :style="valueTextStyles"
            >
                <slot />
            </span>
            <span
                class="annotation"
                :style="annotationTextStyles"
            >
                <slot name="annotation" />
            </span>
            <div
                class="description"
                :style="descriptionTextStyles"
            >
                {{ description }}
                <slot
                    v-if="!isMobile"
                    name="refill"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            required: true,
            type: String,
        },
        description: {
            required: true,
            type: String,
        },
        annotation: {
            required: false,
            type: String,
            default: null,
        },
    },
    computed: {
        containerStyles() {
            return {
                flexDirection: this.isMobile ? 'column' : 'row',
            }
        },
        textStyles() {
            return {
                paddingLeft: this.isMobile ? '0' : '12px',
                textAlign: this.isMobile ? 'center' : 'left',
            }
        },
        iconStyles() {
            return {
                width: (this.isMobile ? 20 : 40) + 'px',
                height: (this.isMobile ? 20 : 40) + 'px',
            }
        },
        valueTextStyles() {
            return {
                fontSize: (this.isMobile ? 10 : 21) + 'px',
            }
        },
        annotationTextStyles() {
            return {
                fontSize: (this.isMobile ? 8 : 13) + 'px',
            }
        },
        descriptionTextStyles() {
            return {
                fontSize: (this.isMobile ? 8 : 13) + 'px',
            }
        },
        isMobile() {
            return this.$vuetify.breakpoint.width < 1264;
        },
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.icon_container {
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
        background-size: 100%;
    }
    .icon_gold {
        background-image: url("../../images/icon/gold.png");
    }
    .icon_diamond {
        background-image: url("../../images/icon/diamond.png");
    }
    .icon_experience {
        background-image: url("../../images/icon/experience.png");
    }
    .icon_energy {
        background-image: url("../../images/icon/energy.png");
    }
    .icon_ranking {
        background-image: url("../../images/icon/ranking.png");
    }
    .icon_melee {
        background-image: url("../../images/icon/melee.png");
    }
    .icon_limitPoints {
        background-image: url("../../images/icon/limitpoints.png");
    }
    .icon_common {
        background-image: url("../../images/icon/common.png");
    }
    .icon_uncommon {
        background-image: url("../../images/icon/uncommon.png");
    }
    .icon_rare {
        background-image: url("../../images/icon/rare.png");
    }
    .icon_epic {
        background-image: url("../../images/icon/epic.png");
    }
    .icon_legendary {
        background-image: url("../../images/icon/legendary.png");
    }
}

</style>
