<template>
    <div class="payment_item">
        <img :src="image" />
        <div class="center mb1">
            {{ quantity }} {{ $t('diamond') }}
        </div>
        <cc-buttonbar class="mb1">
            <cc-button @click="$router.push({ name: 'payment_methods', params: { id }})">
                {{ price }} Euro
            </cc-button>
        </cc-buttonbar>
    </div>
</template>

<script>
import Button from "./Button.vue";
import Buttonbar from "./Buttonbar.vue";

export default {
    components: {
        'cc-buttonbar': Buttonbar,
        'cc-button': Button,
    },
    props: {
        id: {
            required: true,
            type: String,
        },
        price: {
            required: true,
            type: String,
        },
        quantity: {
            required: true,
            type: Number,
        }
    },
    data() {
        return {
            image: require('../../images/payment_item/' + this.id + '.png'),
        }
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.payment_item {
    color: $color-text-light;
    background-color: #262825;
    border: 4px solid transparent;
    border-image: url("../../images/frame_payment_item.png") 4 repeat;
}
</style>
