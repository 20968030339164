<template>
    <div
        class="die"
        :class="classes"
        :style="styles"
        @transitionend="$emit('dropped')"
    >
        <template v-if="!blind">
            <span
                v-for="i in face"
                :key="i"
                class="pip"
                :style="pipStyles"
            />
        </template>
        <span
            v-if="blind"
            class="blind"
        >
            ?
        </span>
    </div>
</template>

<script>
export default {
    props: {
        face: {
            type: Number,
            required: true,
        },
        highlight: {
            type: Boolean,
            required: false,
            default: false,
        },
        clickable: {
            type: Boolean,
            required: false,
            default: false,
        },
        visible: {
            type: Boolean,
            required: false,
            default: true,
        },
        blind: {
            type: Boolean,
            required: false,
            default: false,
        },
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            x: 0,
            y: 0,
        }
    },
    computed: {
        styles() {
            return {
                left: this.x + 'px',
                top: this.y + 'px',
                visibility: this.visible ? 'visible' : 'hidden',
                opacity: this.visible ? 1.0 : 0.0,
                width: (this.small ? 16 : 64) + 'px',
                height: (this.small ? 16 : 64) + 'px',
                margin: ((this.small ? 8 : 16) - (this.highlight ? 2 : 0)) + 'px',
                padding: (this.small ? 4 : 10) + 'px',
                boxShadow: this.small ? 'inset 0 3px white, inset 0 -3px #bbb, inset 3px 0 #d7d7d7, inset -3px 0 #d7d7d7' : 'inset 0 5px white, inset 0 -5px #bbb, inset 5px 0 #d7d7d7, inset -5px 0 #d7d7d7',
            }
        },
        classes() {
            const classes = [];
            if (this.highlight) {
                classes.push('highlight');
            }
            if (this.clickable) {
                classes.push('clickable');
            }

            return classes.join(' ');
        },
        pipStyles() {
            return {
                width: (this.small ? 4 : 16) + 'px',
                height: (this.small ? 4 : 16) + 'px',
                boxShadow: this.small ? 'inset 0 1px #111, inset 0 -1px #555' : 'inset 0 3px #111, inset 0 -3px #555',
            }
        },
    },
    methods: {
        moveTo(x, y) {
            this.x = x;
            this.y = y;
        },
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.die {
    position: relative;
    box-sizing: content-box;
    display: grid;
    grid-template-areas:
            "a . c"
            "e g f"
            "d . b";
    flex: 0 0 auto;
    background-color: #e7e7e7;
    border-radius: 10%;
    transition:
        left 0.5s cubic-bezier(.42, -0.3, .78, 1.25),
        top 0.5s cubic-bezier(.42, -0.3, .78, 1.25),
        opacity 0.5s ease-out,
        visibility 0.5s ease-out;
    &.highlight {
        border: 2px solid #352114;
    }
    .pip {
        display: block;
        align-self: center;
        justify-self: center;
        border-radius: 50%;
        background-color: #333;
        &:nth-child(2) {
            grid-area: b;
        }
        &:nth-child(3) {
            grid-area: c;
        }
        &:nth-child(4) {
            grid-area: d;
        }
        &:nth-child(5) {
            grid-area: e;
        }
        &:nth-child(6) {
            grid-area: f;
        }
        &:nth-child(odd):last-child {
            grid-area: g;
        }
    }
    .blind {
        font-size: 3rem;
        grid-area: g;
        align-self: center;
        justify-self: center;
    }
    &.clickable {
        cursor: pointer;
    }
}
</style>
