<template>
    <cc-frame-big :headline="$t('melee.vs')">
        <cc-loading v-if="loading" />
        <div v-else>
            <div class="avatars">
                <cc-avatar
                    :gender="user.avatarGender"
                    :type="user.avatarType"
                    side="left"
                />
                <cc-avatar
                    :gender="opponent.avatarGender"
                    :type="opponent.avatarType"
                    side="right"
                />
            </div>
            <table>
                <tr>
                    <td class="username">
                        <router-link
                            :to="{ name: 'profile', params: { id: user.id }}"
                            class="user"
                        >
                            {{ user.username }}
                        </router-link>
                    </td>
                    <td />
                    <td class="username">
                        <router-link
                            :to="{ name: 'profile', params: { id: opponent.id }}"
                            class="user"
                        >
                            {{ opponent.username }}
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td>{{ user.level }}</td>
                    <td>{{ $t('general.level') }}</td>
                    <td>{{ opponent.level }}</td>
                </tr>
                <tr>
                    <td>{{ user.dice }}</td>
                    <td>{{ $t('melee.dice') }}</td>
                    <td>{{ opponent.dice }}</td>
                </tr>
                <tr>
                    <td>{{ user.hitpoints }}</td>
                    <td>{{ $t('melee.hitpoints') }}</td>
                    <td>{{ opponent.hitpoints }}</td>
                </tr>
                <tr>
                    <td colspan="3">
                        <template v-if="user.meleeLeft === 0">
                            {{ $t('melee.hint.no_melee_left') }} <a @click="showDiamondMeleeLayer()">{{ $t('general.refill') }}</a>
                        </template>
                        <template v-else>
                            <cc-buttonbar>
                                <cc-button @click="fight(type)">
                                    {{ $t('button.attack') }}
                                </cc-button>
                            </cc-buttonbar>
                        </template>
                    </td>
                </tr>
            </table>
        </div>
    </cc-frame-big>
</template>

<script>
import BuyMeleeLayer from "../model/dto/layer/BuyMeleeLayer";

import Avatar from '../component/Avatar.vue';
import Button from '../component/Button.vue';
import Buttonbar from '../component/Buttonbar.vue';
import Loading from '../component/Loading.vue';
import FrameBig from "../component/FrameBig";

export default {
    components: {
        'cc-frame-big': FrameBig,
        'cc-avatar': Avatar,
        'cc-button': Button,
        'cc-buttonbar': Buttonbar,
        'cc-loading': Loading,
    },
    props: {
        type: {
            required: true,
            type: String,
        },
        id: {
            required: true,
            type: String,
        }
    },
    data() {
        return {
            opponent: {},
            loading: true,
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        levelRange() {
            return this.user.premiumMeleeLeft > 0 ? 3 : 1;
        },
        isInLevelRange() {
            return Math.abs(this.user.level - this.opponent.level) <= this.levelRange;
        }
    },
    created() {
        this.$http
            .get('/users/' + this.id)
            .then(response => {
                this.opponent = response.data.data;
                this.loading = false;
            })
    },
    methods: {
        fight(type) {
            let errors = [];
            if (this.user.currentMelee === 0) {
                errors.push(this.$t('error.out_of_melee'));
            }
            let requiredLevel = type === 'ranking' ? 3 : 5;
            if (this.user.level < requiredLevel) {
                errors.push(this.$t('error.level_too_low'));
            }
            if (type === 'gold' && !this.isInLevelRange) {
                errors.push(this.$t('error.attack_level_range', {'level_range': this.levelRange}));
            }
            if (this.opponent.timeUntilNextGoldMelee) {
                errors.push(this.$t('error.attack_time_limit', { time_left: this.opponent.timeUntilNextGoldMelee }));
            }

            if (errors.length > 0) {
                window.eventbus.$emit('errors', errors);
                return;
            }

            this.$http
                .post('/melees', { type: type, id: this.opponent.id })
                .then(response => {
                    this.$router.push({ name: 'fight', params: { id: response.headers['x-identifier'] } });
                });
        },
        showDiamondMeleeLayer() {
            this.$store.commit('addLayer', new BuyMeleeLayer());
        }
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
table {
    width: 100%;
    text-align: center;
}
.avatars {
    display: flex;
    justify-content: space-between;
}
.username {
    font-size: 19px;
    font-weight: bold;
    width: 35%;
}
</style>
