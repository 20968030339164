export default class Context {

    constructor(context) {
        this.context = context;
    }

    clear() {
        this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height);
    }

    beginPath() {
        this.context.beginPath();
    }

    closePath() {
        this.context.closePath();
    }

    moveTo(coordinate) {
        this.context.moveTo(coordinate.x, coordinate.y);
    }

    lineTo(coordinate) {
        this.context.lineTo(coordinate.x, coordinate.y);
    }

    stroke(style, width, dash) {
        this.context.strokeStyle = style ? style : 'black';
        this.context.lineWidth = width ? width : 1;
        this.context.setLineDash(dash ? dash : []);
        this.context.stroke();
    }

    circle(coordinate, radius) {
        this.context.arc(coordinate.x, coordinate.y, radius, 0, 2 * Math.PI);
    }

    fill(style) {
        this.context.fillStyle = style ? style : 'black';
        this.context.fill();
    }

    text(coordinate, text, fontSize, fillStyle) {
        this.context.font = 'bold ' + fontSize + 'px "Overlock", cursive';
        this.context.fillStyle = fillStyle ? fillStyle : 'black';
        this.context.textAlign = 'center';
        this.context.fillText(text, coordinate.x, coordinate.y);
    }
}
