<template>
    <cc-frame-small :headline="headline">
        <h4 v-if="intro">
            {{ intro }}
        </h4>
        <slot />
        <div
            v-if="note"
            class="note mt1"
        >
            {{ note }}
        </div>
    </cc-frame-small>
</template>

<script>
import FrameSmall from './FrameSmall.vue';

export default {
    components: {
        'cc-frame-small': FrameSmall,
    },
    props: {
        headline: {
            type: String,
            required: true,
        },
        intro: {
            type: String,
            required: false,
            default: null,
        },
        note: {
            type: String,
            required: false,
            default: null,
        }
    }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.note {
    font-size: 11px;
}
</style>
