<template>
    <div
        class="creeper"
        :style="{ backgroundImage }"
    >
        <template v-if="!found">
            ?
        </template>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            required: true,
            type: String,
        },
        image: {
            required: true,
            type: String,
        },
        frequency: {
            required: true,
            type: String,
        },
        found: {
            required: true,
            type: Boolean,
        },
    },
    computed: {
        backgroundImage() {
            if (!this.found) {
                return 'url(' + require('../../images/creeper/book/' + this.frequency + '/empty.jpg') + ')';
            }

            return 'url(' + require('../../images/creeper/book/' + this.frequency + '/' + this.image) + ')';
        }
    }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.creeper {
    width: 92px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5rem;
    color: #34342c;
}
</style>
