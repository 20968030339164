<template>
    <div>
        <cc-loading v-if="loading" />
        <div v-else>
            <table>
                <thead>
                    <tr>
                        <th>{{ $t('date') }}</th>
                        <th>{{ $t('message') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(log, index) in logs"
                        :key="index"
                    >
                        <td>{{ date(log.createdAt) }}</td>
                        <td>{{ log.message }}</td>
                    </tr>
                    <tr v-if="logs.length === 0">
                        <td colspan="2">
                            {{ $t('no_messages') }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Loading from '../../component/Loading.vue';
import axios from "../../axios";

export default {
    components: {
        'cc-loading': Loading,
    },
    data() {
        return {
            logs: [],
            loading: true,
        }
    },
    created() {
        axios
            .get('/logs')
            .then(response => {
                this.logs = response.data.data;
                this.loading = false;
            });
    },
    methods: {
        date(date) {
            return new Date(Date.parse(date)).toLocaleString();
        },
    },
}
</script>
