<template>
    <div class="subcontent">
        <p><span class="amount">{{ $t('treasure.balance') }} {{ user.treasure }}</span></p>
        <div>
            <label>
                <div>{{ $t('treasure.transfer_in', { 'fee': user.treasureFee }) }}</div>
                <div style="display: flex">
                    <cc-input
                        v-model="payinMoney"
                        type="number"
                    />
                    <cc-button @click="payin()">einzahlen</cc-button>
                </div>
            </label>
        </div>
        <div class="mt1">
            <label>
                <div>{{ $t('treasure.transfer_out') }}</div>
                <div style="display: flex">
                    <cc-input
                        v-model="payoutMoney"
                        type="number"
                    />
                    <cc-button @click="payout()">abheben</cc-button>
                </div>
            </label>
        </div>
        <cc-disabled :level="5" />
    </div>
</template>

<script>
import TreasureRepository from "../../repository/TreasureRepository";

import Button from '../../component/Button.vue';
import Disabled from '../../component/Disabled.vue';
import Input from "../../component/Input";
import UserRepository from "../../repository/UserRepository";

export default {
    components: {
        'cc-button': Button,
        'cc-disabled': Disabled,
        'cc-input': Input,
    },
    data() {
        return {
            treasures: [],
            payinMoney: 0,
            payoutMoney: 0,
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
    },
    created() {
        this.payinMoney = this.user.premiumMoneyLeft > 0 ? (this.user.gold - this.user.treasure) : this.user.gold;
        this.payoutMoney = this.user.treasure;
    },
    methods: {
        payin() {
            TreasureRepository.payin(this.payinMoney).then(() => UserRepository.findMe()).then(user => this.$store.commit('loadUser', user));
        },
        payout() {
            TreasureRepository.payout(this.payoutMoney).then(() => UserRepository.findMe()).then(user => this.$store.commit('loadUser', user));
        },
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.amount {
    font-weight: bold;
}
</style>
