import axios from 'axios';
import uuid4 from 'uuid/v4';
import authentication from "../authentication";

export default class UserRepository {

    static authenticate(username, password, platform) {
        const apiKey = uuid4();

        return new Promise(resolve => {
            axios
                .post('/authentication', { username, password, apiKey, platform })
                .then(() => {
                    authentication.setApiKey(apiKey);
                    resolve();
                });
        })
    }

    static add(username, password) {
        return new Promise(resolve => {
            axios
                .post('/users', { username, password })
                .then(() => resolve());
        })
    }

    static findMe() {
        return new Promise(resolve => {
            axios
                .get('/users/me')
                .then(response => resolve(response.data.data));
        });
    }

    static findNewbieRanking() {
        return new Promise(resolve => {
            axios
                .get('/rankings/newbie')
                .then(response => resolve(response.data.data));
        });
    }

    static findMeleeRanking() {
        return new Promise(resolve => {
            axios
                .get('/rankings/melee')
                .then(response => resolve(response.data.data));
        });
    }

    static findGoldRanking(offset) {
        return new Promise(resolve => {
            axios
                .get('rankings/gold?offset=' + offset)
                .then(response => resolve(response.data.data));
        });
    }
}
