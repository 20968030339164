<template>
    <div class="user">
        <cc-fighter-avatar
            :side="side"
            :fighter="fighter"
        />
        <div class="values">
            <div class="life">
                <cc-dynamic-number :value="fighter.hitpoints" />
            </div>
            <div class="extra">
                <div>
                    <i class="flaticon-dice-protect" /> <cc-dynamic-number :value="fighter.shield" />
                </div>
                <div>
                    <i class="flaticon-dice-plan" /> <cc-dynamic-number :value="fighter.poison" />
                </div>
                <div>
                    <i class="flaticon-dice-agility" /> <cc-dynamic-number :value="fighter.camouflage" />
                </div>
            </div>
        </div>
        <div class="username">
            <router-link
                :to="{ name: 'profile', params: { id: fighter.id }}"
                class="user"
            >
                {{ fighter.username }}
            </router-link>
        </div>
        <cc-buttonbar
            v-show="onTurn"
            class="mt1"
        >
            <cc-button @click="$emit('end-turn')">
                {{ $t('general.end_turn') }}
            </cc-button>
        </cc-buttonbar>
    </div>
</template>

<script>
import FighterAvatar from "./FighterAvatar";
import DynamicNumber from "../DynamicNumber";
import Buttonbar from "../Buttonbar";
import Button from "../Button";

export default {
    components: {
        'cc-fighter-avatar': FighterAvatar,
        'cc-dynamic-number': DynamicNumber,
        'cc-buttonbar': Buttonbar,
        'cc-button': Button,
    },
    props: {
        side: {
            type: String,
            required: true,
        },
        fighter: {
            type: Object,
            required: true,
        },
        onTurn: {
            type: Boolean,
            required: true,
        },
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../../css/variables";

.user {
    .avatar {
        margin: 0 auto;
    }
    .values {
        display: flex;
        justify-content: center;
        .life {
            font-weight: bold;
            font-size: 48px;
        }
        .extra {
            margin-left: 0.5rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    .username {
        text-align: center;
        font-weight: bold;
    }
}
</style>
