<template>
    <div class="subcontent">
        <table>
            <tr>
                <td colspan="2">
                    {{ $t('skills.limits.points_available') }}
                </td>
                <td>
                    <cc-icon
                        type="limitPoints"
                        :description="$t('general.limitpoints')"
                    >
                        {{ user.limitPoints }}
                    </cc-icon>
                </td>
            </tr>
            <tr>
                <td>
                    <cc-icon
                        type="energy"
                        :description="$t('energy')"
                    >
                        {{ user.maxEnergy }}
                    </cc-icon>
                </td>
                <td>
                    <a
                        class="plus"
                        @click="increaseLimit('energy')"
                    />
                </td>
                <td>
                    <cc-icon
                        type="limitPoints"
                        :description="$t('general.limitpoints')"
                    >
                        {{ user.priceLimitEnergy }}
                    </cc-icon>
                </td>
            </tr>
            <tr>
                <td>
                    <cc-icon
                        type="melee"
                        :description="$t('general.melee')"
                    >
                        {{ user.maxMelee }}
                    </cc-icon>
                </td>
                <td>
                    <a
                        class="plus"
                        @click="increaseLimit('melee')"
                    />
                </td>
                <td>
                    <cc-icon
                        type="limitPoints"
                        :description="$t('general.limitpoints')"
                    >
                        {{ user.priceLimitMelee }}
                    </cc-icon>
                </td>
            </tr>
        </table>
        <cc-disabled :level="7" />
    </div>
</template>

<script>
import Disabled from '../../component/Disabled.vue';
import Icon from '../../component/Icon.vue';

import UserRepository from "../../repository/UserRepository";
import axios from "../../axios";

export default {
    components: {
        'cc-disabled': Disabled,
        'cc-icon': Icon,
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    },
    methods: {
        increaseLimit(limit) {
            axios
                .post('/skills/limit/' + limit)
                .then(() => UserRepository.findMe())
                .then(user => this.$store.commit('loadUser', user));
        }
    }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
table {
    td {
        padding-right: 32px;
        .plus {
            width: 25px;
            height: 19px;
            background-image: url("../../../images/plus.png");
            display: block;
            cursor: pointer;
            &:hover {
                background-position: 0 -19px;
            }
            &:active {
                background-position: 0 -38px;
            }
        }
    }
}
</style>
