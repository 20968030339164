<template>
    <cc-layer :headline="$t('general.levelup')">
        <h3>{{ $t('info.level_reached', { level }) }}</h3>
        <div>{{ $t('info.reward') }}</div>
        <div>
            <cc-icon
                v-if="gold > 0"
                type="gold"
                :description="$t('gold')"
            >
                {{ gold }}
            </cc-icon>
            <cc-icon
                v-if="experience > 0"
                type="experience"
                :description="$t('experience')"
            >
                {{ experience }}
            </cc-icon>
            <cc-icon
                v-if="limitPoints > 0"
                type="limitPoints"
                :description="$t('general.limitpoints')"
            >
                {{ limitPoints }}
            </cc-icon>
            <div
                v-for="(feature, index) in features"
                :key="index"
            >
                {{ $t('levelup.level' + level + '.feature' + feature) }}
            </div>
            <div>{{ $t('info.energy_and_melee_maxed') }}</div>
        </div>
        <cc-buttonbar class="mt1">
            <cc-button @click="close()">
                {{ $t('button.ok') }}
            </cc-button>
        </cc-buttonbar>
    </cc-layer>
</template>

<script>
import Button from '../../component/Button.vue';
import Buttonbar from '../../component/Buttonbar.vue';
import Icon from '../../component/Icon.vue';
import Layer from '../../component/Layer.vue';
import GameEventRepository from "../../repository/GameEventRepository";
import UserRepository from "../../repository/UserRepository";

export default {
    components: {
        'cc-button': Button,
        'cc-buttonbar': Buttonbar,
        'cc-icon': Icon,
        'cc-layer': Layer,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        level: {
            required: true,
            type: Number,
        },
        gold: {
            required: true,
            type: Number,
        },
        experience: {
            type: Number,
            required: true,
        },
        limitPoints: {
            type: Number,
            required: true,
        },
        features: {
            required: true,
            type: Number,
        }
    },
    methods: {
        close() {
            GameEventRepository
                .remove(this.id)
                .then(() => {
                    UserRepository.findMe();
                    this.$store.commit('removeLayer', this);
                });
        }
    },
}
</script>
