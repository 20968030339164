import Vue from 'vue';
import Vuex from 'vuex';

import UserRepository from "./repository/UserRepository";
import authentication from "./authentication";

Vue.use(Vuex);

const user = {
    id: null,
};

export default new Vuex.Store({
    state: {
        loading: true,
        user,
        layers: [],
    },
    getters: {
        messageLayers: state => {
            return state.layers.filter(layer => layer.type === 'message');
        },
        buyLayers: state => {
            return state.layers.filter(layer => layer.type === 'buy');
        },
        errorLayers: state => {
            return state.layers.filter(layer => layer.type === 'error');
        },
        taskLayers: state => {
            return state.layers.filter(layer => layer.type === 'task');
        },
        foundCreeperLayers: state => {
            return state.layers.filter(layer => layer.type === 'found_creeper');
        },
        bookCreeperLayers: state => {
            return state.layers.filter(layer => layer.type === 'book_creeper');
        },
        chaseLayers: state => {
            return state.layers.filter(layer => layer.type === 'chase');
        },
        levelupLayers: state => {
            return state.layers.filter(layer => layer.type === 'levelup');
        },
        isAuthenticated: state => {
            return state.user.id !== null;
        },
        isLoading: state => {
            return state.loading;
        },
    },
    mutations: {
        loadUser(state, user) {
            state.user = user;
        },
        addEnergy(state, energy) {
            state.user.energy += energy;
        },
        updateEnergyTimer(state) {
            state.user.energyTimer = Math.max(0, state.user.energyTimer - 1);
            if (state.user.energyTimer === 0 && state.user.energy < state.user.maxEnergy) {
                state.user.energy = Math.min(state.user.maxEnergy, state.user.energy + 5);
                state.user.energyTimer = secondsForOneUpdate(state.user);
            }
        },
        updateMeleeTimer(state) {
            state.user.meleeTimer = Math.max(0, state.user.meleeTimer - 1);
            if (state.user.meleeTimer === 0 && state.user.currentMelee < state.user.maxMelee) {
                state.user.currentMelee = Math.min(state.user.maxMelee, state.user.currentMelee + 1);
                state.user.meleeTimer = secondsForOneUpdate(state.user);
            }
        },
        addLayer(state, layer) {
            if (state.layers.map(layer => JSON.stringify(layer)).indexOf(JSON.stringify(layer)) === -1) {
                state.layers.push(layer);
            }
        },
        removeLayer(state, layer) {
            state.layers.splice(state.layers.indexOf(layer), 1);
        },
        markLoading(state) {
            state.loading = true;
        },
        markLoaded(state) {
            state.loading = false;
        },
        logout(state) {
            state.user = user;
        },
    },
    actions: {
        loadUser({ commit, dispatch }) {
            if (!authentication.isAuthenticated()) {
                return;
            }

            UserRepository.findMe()
                .then(user => {
                    commit('loadUser', user);
                    setTimeout(() => dispatch('loadUser'), 10000);
                })
                .finally(() => commit('markLoaded'));
        },
        tick({ commit }) {
            commit('updateEnergyTimer');
            commit('updateMeleeTimer');
        }
    }
});

const secondsForOneUpdate = user => {
    let secondsForOneUpdate = Math.ceil(user.level / 2) * 10;
    if (user.premiumBaseLeft > 0) {
        secondsForOneUpdate = Math.round(secondsForOneUpdate * 0.7);
    }

    return secondsForOneUpdate;
};
