<template>
    <cc-frame-big :headline="$t('ranking.name')">
        <cc-tabs>
            <cc-tab
                :route="{ name: 'ranking_melee' }"
                :label="$t('ranking.melee.title')"
            />
            <cc-tab
                :route="{ name: 'ranking_gold' }"
                :label="$t('ranking.gold.title')"
            />
        </cc-tabs>

        <router-view />
    </cc-frame-big>
</template>

<script>
import Tabs from '../component/Tabs.vue';
import Tab from '../component/Tab.vue';
import FrameBig from "../component/FrameBig";

export default {
    components: {
        'cc-frame-big': FrameBig,
        'cc-tabs': Tabs,
        'cc-tab': Tab,
    },
}
</script>
