export default class CellDrawer {

    constructor(context) {
        this.context = context;
    }

    draw(cell) {
        const coordinates = cell.polygon;
        this.context.beginPath();
        this.context.moveTo(coordinates[0]);
        coordinates.forEach(coordinate => this.context.lineTo(coordinate));
        this.context.stroke(cell.getBorderColor(), .1);
        this.context.fill(cell.getColor());
    }

    drawCurrent(cell) {
        const coordinates = cell.polygon;
        this.context.beginPath();
        this.context.moveTo(coordinates[0]);
        coordinates.forEach(coordinate => this.context.lineTo(coordinate));
        this.context.stroke('rgb(255, 0, 0)', .2);
    }

    drawHighlighted(cell) {
        const coordinates = cell.polygon;
        this.context.beginPath();
        this.context.moveTo(coordinates[0]);
        coordinates.forEach(coordinate => this.context.lineTo(coordinate));
        this.context.stroke(cell.getBorderColor(), .2);
    }

    drawBlack(cell) {
        const coordinates = cell.polygon;
        this.context.beginPath();
        this.context.moveTo(coordinates[0]);
        coordinates.forEach(coordinate => this.context.lineTo(coordinate));
        this.context.stroke('rgb(0, 0, 0)', .2);
        this.context.fill('rgb(0, 0, 0)');
    }
}
