<template>
    <div
        class="avatar"
        :style="{ backgroundImage: image }"
    />
</template>

<script>
export default {
    props: {
        gender: {
            required: true,
            type: String,
        },
        type: {
            required: true,
            type: String,
        }
    },
    computed: {
        image() {
            let image = require('../../images/avatar/portrait/' + this.gender + '/' + this.type + '.png');

            return 'url(' + image + ')';
        }
    }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.avatar {
    width: 49px;
    height: 56px;
    border: 4px solid transparent;
    border-image: url("../../images/frame_friend.png") 4 repeat;
}
</style>
