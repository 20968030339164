<template>
    <a
        class="button"
        :class="{ glowing: glowing }"
        @click="click"
    >
        <slot />
    </a>
</template>

<script>
export default {
    props: {
        glowing: {
            required: false,
            type: Boolean,
        },
        stop: {
            required: false,
            type: Boolean,
            default: false,
        }
    },
    methods: {
        click(event) {
            this.$emit('click');
            if (this.stop) {
                event.stopPropagation();
            }
        }
    }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.button {
    background: transparent url("../../images/button.png");
    width: 149px;
    height: 36px;
    display: inline-block;
    text-decoration: none;
    border: 0;
    cursor: pointer;
    text-align: center;
    padding-top: 6px;
    color: $button-text-color !important;
    text-shadow: 1px 1px 1px black;
    user-select: none;
    &:hover {
        background-position: 0 -36px;
    }
    &:active {
        background-position: 0 -72px;
    }
    &.glowing {
        border-radius: 20px;
        animation: glowing-button 2000ms infinite;
    }
}
@keyframes glowing-button {
    0% {
        box-shadow: 0 0 -10px #456d26;
    }
    40% {
        box-shadow: 0 0 20px #456d26;
        background-color: rgba(69, 109, 38, 0.3);
    }
    60% {
        box-shadow: 0 0 20px #456d26;
        background-color: rgba(69, 109, 38, 0.4);
    }
    100% {
        box-shadow: 0 0 -10px #456d26;
    }
}
</style>
