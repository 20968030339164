<!--<template>-->
<!--    <div>-->
<!--        <span v-for="word in description(name)">-->
<!--            <span v-if="word === '[]'" class="slotSymbol"></span>-->
<!--            <template v-else>{{ word }} </template>-->
<!--        </span>-->
<!--    </div>-->
<!--</template>-->

<script>
export default {
    props: {
        name: {
            type: String,
            required: true,
        },
    },
    methods: {
        description() {
            return this.$t('card.' + this.name + '.description').split(' ');
        },
    },
    render(createElement) {
        return createElement('div', this.description().map(word => {
            if (word === '[]') {
                return createElement('span', { attrs: { class: 'symbol' }});
            } else {
                return createElement('span', word);
            }
        }));
    }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

span:after {
    content: ' ';
}
.symbol {
    width: 14px;
    height: 14px;
    border: 2px dotted #140601;
    display: inline-flex;
    margin-right: 3px;
}
</style>
