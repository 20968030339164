<template>
    <div>
        <cc-loading v-if="loading" />
        <div v-else>
            <table width="100%">
                <tr
                    v-for="(gift, index) in gifts"
                    :key="index"
                >
                    <td>
                        <cc-icon
                            type="energy"
                            :description="$t('energy')"
                        >
                            10
                        </cc-icon>
                    </td>
                    <td>{{ $t('from') }} {{ gift.sender.username }}</td>
                    <td>
                        <cc-button @click="giftCollect(gift)">
                            {{ $t('friends.gift.collect.message') }}
                        </cc-button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import Button from '../../component/Button.vue';
import Icon from '../../component/Icon.vue';
import Loading from '../../component/Loading.vue';

export default {
    components: {
        'cc-button': Button,
        'cc-icon': Icon,
        'cc-loading': Loading,
    },
    data() {
        return {
            gifts: [],
            loading: true,
        }
    },
    created() {
        this.$http
            .get('/gifts?include=sender')
            .then(response => {
                this.gifts = response.data.data;
                this.loading = false;
            });
    },
    methods: {
        giftCollect(gift) {
            this.gifts.splice(this.gifts.indexOf(gift), 1);
            this.$http
                .post('/gifts/' + gift.id)
                .then(() => this.$store.commit('addEnergy', 10));
        },
    },
}
</script>
