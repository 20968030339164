import Coordinate from "../Coordinate";

export default class BurgDrawer {

    constructor(context) {
        this.context = context;
    }

    draw(burg) {
        this.context.beginPath();
        this.context.circle(burg.position, 1 / 4);
        this.context.stroke('rgba(0, 0, 0, 0.7)', 1 / 3);
        this.context.fill('white');
        this.context.text(
            burg.position.add(new Coordinate(0, -1)),
            burg.name,
            1,
            'rgba(0, 0, 0, 0.7)'
        );
    }
}
