<template>
    <cc-layer :headline="$t('general.chase')">
        <h3>{{ $t('info.chase_successful') }}</h3>
        <div style="display: flex; margin-top: 2rem">
            <cc-creeper-battle
                :name="creeperName"
                :image="creeperImage"
                :frequency="creeperFrequency"
            />
            <cc-card
                class="ml2"
                :name="cardDefinition.key"
                :usages="cardDefinition.usages"
                :slots="cardDefinition.slots"
                :dice="cardDefinition.dice"
                :hitpoints="cardDefinition.hitpoints"
                :types="cardDefinition.types"
                :selected-dice="null"
                usable
            />
        </div>
        <cc-buttonbar class="mt1">
            <cc-button
                @click="close()"
            >
                {{ $t('button.ok') }}
            </cc-button>
        </cc-buttonbar>
    </cc-layer>
</template>

<script>
import Button from '../../component/Button.vue';
import Buttonbar from '../../component/Buttonbar.vue';
import Layer from '../../component/Layer.vue';
import CreeperBattle from '../../component/CreeperBattle.vue';
import Card from "../Card";
import GameEventRepository from "../../repository/GameEventRepository";
import UserRepository from "../../repository/UserRepository";

export default {
    components: {
        'cc-button': Button,
        'cc-buttonbar': Buttonbar,
        'cc-creeper-battle': CreeperBattle,
        'cc-layer': Layer,
        'cc-card': Card,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        creeperName: {
            required: true,
            type: String,
        },
        creeperImage: {
            required: true,
            type: String,
        },
        creeperFrequency: {
            required: true,
            type: String,
        },
        cardDefinition: {
            required: true,
            type: Object
        },
    },
    methods: {
        close() {
            GameEventRepository
                .remove(this.id)
                .then(() => {
                    UserRepository.findMe();
                    this.$store.commit('removeLayer', this);
                });
        }
    },
}
</script>
