import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

let messages = {
    de: {
        username: 'Username',
        rank: 'Rang',
        minimum_level: 'Ab Level {level}',
        from: 'Von',
        log: 'Log',
        date: 'Datum',
        message: 'Nachricht',
        no_messages: 'Keine neuen Nachrichten.',
        gold: 'Gold',
        diamond: 'Diamanten',
        payment_method: 'Zahlungsart',
        energy: 'Energie',
        experience: 'Experience',
        general: {
            level: 'Level',
            melee: 'Angriff',
            winner: 'Sieger:',
            error: 'Fehler',
            chase: 'Expedition',
            found_creeper: 'Creeper gefunden',
            levelup: 'Levelaufstieg',
            task: 'Aufgabe',
            end_turn: 'Zug beenden',
            limitpoints: 'Limitpoints',
            loading: 'Wird geladen',
            game_loading: 'Spiel wird geladen...',
            refill: 'Auffüllen',
            name: 'Name',
            frequency: 'Häufigkeit',
            overview: 'Übersicht',
        },
        button: {
            ok: 'Ok',
            attack: 'Angreifen',
            finish: 'Abschließen',
            buy: 'Kaufen',
            cancel: 'Abbrechen',
            pagination_next: 'Vor',
            pagination_previous: 'Zurück',
            close: 'Schließen',
        },
        frequency: {
            common: 'Common',
            uncommon: 'Uncommon',
            rare: 'Rare',
            epic: 'Epic',
            legendary: 'Legendary',
        },
        dice: {
            extinguish: 'löschen',
            status_effect: {
                frozen: 'eingefroren',
                burning: 'brennt',
                blind: 'blind',
                locked: 'gesperrt',
            },
        },
        card_type: {
            damage: 'Macht Schaden',
            shield: 'Erhöht den Schild',
            health: 'Regeneriert Lebenspunkte',
            diceModifier: 'Verändert Würfel',
        },
        card: {
            poke: {
                name: 'Poke',
                description: 'Macht 2 Schaden',
            },
            steal: {
                name: 'Stealth',
                description: 'Erhöht Tarnung um 1',
            },
            stealth: {
                name: 'Steal',
                description: 'Klaut eine Karte des Gegners',
            },


            absolute_zero: {
                name: 'Absolute Zero',
                description: 'Do 25 damage, freeze all dice',
            },
            air_crystal: {
                name: 'Air Crystal',
                description: 'Do [] damage, + 1 shock'
            },
            avalanche: {
                name: 'Avalanche',
                description: 'Do 3 damage, reroll with lower value',
            },
            bandage: {
                name: 'Bandage',
                description: 'Heal 1 health',
            },
            battle_axe: {
                name: 'Kampfaxt',
                description: 'Macht 2 x [] Schaden',
            },
            bear_maul: {
                name: 'Bear Maul',
                description: 'Do [] damage',
            },
            bear_swipe: {
                name: 'Bear Swipe',
                description: 'Do [] damage, return the dice',
            },
            bear_trap: {
                name: 'Bear Trap',
                description: 'Lock 1 dice',
            },
            bee_sting: {
                name: 'Bee Sting',
                description: 'Do 4 damage, inflict 1 shock'
            },
            beetle_headbutt: {
                name: 'Beetle Headbutt',
                description: 'Do 5 damage, lock 1 dice',
            },
            befuddle: {
                name: 'Befuddle',
                description: 'Duplicate one dice, return the other',
            },
            big_moment: {
                name: 'Big Moment',
                description: 'Do 7 damage',
            },
            blast_chiller: {
                name: 'Blast Chiller',
                description: '4 damage, freeze 1 dice',
            },
            blender: {
                name: 'Blender',
                description: 'Split a dice into 1s',
            },
            blight: {
                name: 'Blight',
                description: 'Double poison',
            },
            blizzard: {
                name: 'Blizzard',
                description: 'Do [] damage, freeze 1 dice',
            },
            boomerang: {
                name: 'Boomerang',
                description: 'Do 2 x [] to enemy, but [] to yourself',
            },
            boop: {
                name: 'Boop',
                description: 'Do 2 damage',
            },
            bop: {
                name: 'Bop',
                description: 'Do 3 damage',
            },
            breadcrumbs: {
                name: 'Breadcrumbs',
                description: 'Heal 1, add 1 shield',
            },
            broadsword: {
                name: 'Breitschwert',
                description: 'Macht [] + 2 Schaden',
            },
            bronze_cauldron: {
                name: 'Bronze Cauldron',
                description: 'Do 2 damage, get an odd dice',
            },
            buckler: {
                name: 'Buckler',
                description: 'Erhoeht Schild um 4',
            },
            bump: {
                name: 'Bump',
                description: 'Dice value +1',
            },
            bumpblade: {
                name: 'Bumpblade',
                description: 'Do 1 damage, get new dice (value +1)',
            },
            burning_light: {
                name: 'Burning Light',
                description: 'Deal [] damage, burn 1 dice',
            },
            buzzer: {
                name: 'Buzzer',
                description: 'Inflict 1 shock'
            },
            camouflage: {
                name: 'Camouflage',
                description: 'Blind 2 dice',
            },
            candle: {
                name: 'Candle',
                description: 'Dice value -1, Lose 1 health',
            },
            cannon_fire: {
                name: 'Cannon Fire',
                description: 'Do 2 damage, inflict 1 burn',
            },
            capacitor: {
                name: 'Capacitor',
                description: '2 damage + 1 shock'
            },
            catastrophe: {
                name: 'Catastrophe',
                description: 'Inflict 1 shock, 1 burn, and 1 freeze'
            },
            cauldron: {
                name: 'Cauldron',
                description: 'Do 1 damage, get a new dice',
            },
            chisel: {
                name: 'Chisel',
                description: 'Dice value -1, roll a 1',
            },
            claw: {
                name: 'Claw',
                description: 'Do [] damage and add 1 poison',
            },
            combat_roll: {
                name: 'Kampfrolle',
                description: 'Würfle diesen Würfel erneut',
            },
            combat_spatula: {
                name: 'Combat Spatula',
                description: 'Flip a dice upside down',
            },
            counterfeit: {
                name: 'Counterfeit',
                description: 'Duplicate a dice',
            },
            crossbow: {
                name: 'Crossbow',
                description: 'Do 9 damage',
            },
            dagger: {
                name: 'Dagger',
                description: 'Do [] damage',
            },
            detonator: {
                name: 'Detonator',
                description: 'Do 1 damage for every 1 poison on enemy',
            },
            dice_cannon: {
                name: 'Dice Cannon',
                description: 'Do 1 damage',
            },
            divine_hammer: {
                name: 'Divine Hammer',
                description: 'Do 2 x [] damage, shock all cards'
            },
            doppeldice: {
                name: 'Doppeldice',
                description: 'Double dice value',
            },
            doppeltwice: {
                name: 'Doppeltwice',
                description: 'Double dice value',
            },
            double_edge: {
                name: 'Double Edge',
                description: 'Do [] damage to both enemy and you',
            },
            duplicate: {
                name: 'Duplicate',
                description: 'Duplicate a dice',
            },
            dust_cloud: {
                name: 'Dust Cloud',
                description: 'Do [] damage, on 6, blind 2 dice',
            },
            elastic_heart: {
                name: 'Elastic Heart',
                description: 'Do [] damage, recover 1 health',
            },
            electric_shock: {
                name: 'Electric Shock',
                description: 'Do [] damage, + 1 shock'
            },
            fireball: {
                name: 'Fireball',
                description: 'Deal [] damage, burn 1 dice',
            },
            fire_breath: {
                name: 'Fire Breath',
                description: 'Do 4 damage, burn 1 dice',
            },
            fire_crystal: {
                name: 'Fire Crystal',
                description: 'Deal [] damage, burn 1 dice',
            },
            first_aid_kit: {
                name: 'First Aid Kit',
                description: 'Heal 8 health',
            },
            fishing_net: {
                name: 'Fishing Net',
                description: 'Do [] damage, lock 1 dice on 3',
            },
            fists: {
                name: 'Fists',
                description: 'Do 3 damage',
            },
            flame_spell: {
                name: 'Flame Spell',
                description: 'Deal 5 damage, burn 2 dice',
            },
            fortify: {
                name: 'Fortify',
                description: 'Increase Shield shield by 50 percent',
            },
            four_handed_sword: {
                name: 'Four Handed Sword',
                description: 'Do [] damage',
            },
            freeze_spell: {
                name: 'Freeze Spell',
                description: 'Deal 5 damage, freeze 2 dice',
            },
            frogsword: {
                name: 'Frogsword',
                description: 'Do [] damage',
            },
            front_line: {
                name: 'Front Line',
                description: 'Lose 3 health, gain 3 shield, return the dice',
            },
            furnace: {
                name: 'Furnace',
                description: '4 damage, burn 1 dice',
            },
            gemstone_staff: {
                name: 'Gemstone Staff',
                description: 'Do 6 damage, freeze 1 dice',
            },
            glare: {
                name: 'Glare',
                description: 'Lock 1 dice',
            },
            glass_cauldron: {
                name: 'Glass Cauldron',
                description: 'Freeze 1 dice, roll a one',
            },
            glue: {
                name: 'Glue',
                description: 'Lock 1 dice',
            },
            gold_dagger: {
                name: 'Gold Dagger',
                description: 'Do [] damage, if even, get a new dice',
            },
            gold_cauldron: {
                name: 'Gold Cauldron',
                description: 'Do 1 damage, get an even dice',
            },
            grand_finale: {
                name: 'Grand Finale',
                description: 'Do 15 damage',
            },
            gumball_machine: {
                name: 'Gumball Machine',
                description: 'Recover 1 health, get a new dice',
            },
            hacksaw: {
                name: 'Hacksaw',
                description: 'Split a dice in three',
            },
            hailstone: {
                name: 'Hailstone',
                description: 'Do 2 damage, inflict 1 freeze',
            },
            half_moon_charm: {
                name: 'Half Moon Charm',
                description: 'Roll a six',
            },
            hammer: {
                name: 'Hammer',
                description: 'Do [] damage, on 6, inflict 1 shock'
            },
            headbutt: {
                name: 'Headbutt',
                description: 'Do [] damage',
            },
            healing_crystal: {
                name: 'Healing Crystal',
                description: 'Recover [] health',
            },
            hibernate: {
                name: 'Hibernate',
                description: 'Recover [] health',
            },
            hollow_crystal: {
                name: 'Hollow Crystal',
                description: 'Do [] damage',
            },
            honey: {
                name: 'Honey',
                description: 'Reroll the dice, get a higher value than before',
            },
            ice_breath: {
                name: 'Ice Breath',
                description: 'Do 4 damage, inflict 1 freeze',
            },
            ice_crystal: {
                name: 'Ice Crystal',
                description: 'Do [] damage, freeze 1 dice',
            },
            ice_shard: {
                name: 'Ice Shard',
                description: 'Do [] damage, freeze 1 dice',
            },
            icecube: {
                name: 'Icecube',
                description: 'Inflict 1 freeze',
            },
            icicle: {
                name: 'Icicle',
                description: 'Do 2 damage, inflict 1 freeze',
            },
            inferno: {
                name: 'Inferno',
                description: 'Do [] damage, burn 1 dice',
            },
            infliction: {
                name: 'Infliction',
                description: 'Do 3 damage',
            },
            ink_splat: {
                name: 'Ink Splat',
                description: 'Blind 1 dice',
            },
            iron_shield: {
                name: 'Iron Shield',
                description: 'Add [] shield',
            },
            juggling_ball: {
                name: 'Juggling Ball',
                description: 'Do [] damage, get a new dice',
            },
            katana: {
                name: 'Katana',
                description: 'Do [] damage',
            },
            keyblade: {
                name: 'Keyblade',
                description: 'Do 3 damage, return the dice',
            },
            kite_shield: {
                name: 'Kite Shield',
                description: 'Add 8 to shield',
            },
            knuckle_duster: {
                name: 'Knuckle Duster',
                description: 'Do 3 damage',
            },
            kunai: {
                name: 'Kunai',
                description: 'Do 2 x [] damage',
            },
            lament: {
                name: 'Lament',
                description: 'Do [] damage, on 6, inflict 1 freeze',
            },
            life_crystal: {
                name: 'Life Crystal',
                description: 'Recover [] health',
            },
            lighter: {
                name: 'Lighter',
                description: 'Do 2 damage, burn 1 dice',
            },
            lightning_bolt: {
                name: 'Lightning Bolt',
                description: 'Do [] damage, + 1 shock'
            },
            liquid_nitrogen: {
                name: 'Liquid Nitrogen',
                description: 'Do [] damage, on 4, inflict 1 freeze',
            },
            liquorice: {
                name: 'Liquorice',
                description: 'Add 2 poison',
            },
            lockpick: {
                name: 'Lockpick',
                description: 'Split a dice in two',
            },
            lollipop: {
                name: 'Lollipop',
                description: 'Dice value -1. On 1, instead return two 1s',
            },
            lucky_roll: {
                name: 'Lucky Roll',
                description: 'Reroll the dice, get a higher value than before',
            },
            lucky_star: {
                name: 'Lucky Star',
                description: 'Do 1 damage, roll a 5',
            },
            magic_barrier: {
                name: 'Magic Barrier',
                description: 'Add 5 shield',
            },
            magic_dagger: {
                name: 'Magic Dagger',
                description: 'Do 2 damage',
            },
            magic_key: {
                name: 'Magic Key',
                description: 'Split a dice in two',
            },
            magic_missile: {
                name: 'Magic Missile',
                description: 'Do 5 damage',
            },
            magic_shield: {
                name: 'Magic Shield',
                description: 'Add 3 shield',
            },
            magic_spear: {
                name: 'Magic Spear',
                description: 'Do 10 damage',
            },
            matchstick: {
                name: 'Matchstick',
                description: 'Burns 1 dice',
            },
            mega_storm: {
                name: 'Mega Storm',
                description: 'Do 20 damage, shock all equipment'
            },
            meteor: {
                name: 'Meteor',
                description: 'Do 20 damage, burn all dice',
            },
            midnight_charm: {
                name: 'Midnight Charm',
                description: 'Roll 2 sixes',
            },
            missing_score: {
                name: 'Missing Score',
                description: 'Do [] damage 25% chance to return dice',
            },
            molotov_cocktail: {
                name: 'Molotov Cocktail',
                description: 'Do [] damage, on 4, burn 1 dice',
            },
            nightmare: {
                name: 'Nightmare',
                description: 'Do 999 damage',
            },
            nudge: {
                name: 'Nudge',
                description: 'Dice value -1',
            },
            nudgeblade: {
                name: 'Nudgeblade',
                description: 'Do [] damage, get new dice (value -1)',
            },
            nunchucks: {
                name: 'Nunchucks',
                description: 'Do [] damage',
            },
            pea_shooter: {
                name: 'Pea Shooter',
                description: 'Do 2 damage',
            },
            plasma_blaster: {
                name: 'Plasma Blaster',
                description: 'Do [] damage',
            },
            plasma_cannon: {
                name: 'Plasma Cannon',
                description: 'Do 10 damage',
            },
            plaster: {
                name: 'Plaster',
                description: 'Heal 1 health',
            },
            pocket_knife: {
                name: 'Pocket Knife',
                description: 'Do [] damage',
            },
            poison_cloud: {
                name: 'Poison Cloud',
                description: 'Inflict [] poison',
            },
            poison_needle: {
                name: 'Poison Needle',
                description: 'Inflict 1 poison',
            },
            poison_slingshot: {
                name: 'Poison Slingshot',
                description: 'Add 4 poison',
            },
            poison_spell: {
                name: 'Poison Spell',
                description: 'Inflict 4 poison',
            },
            princess_guard: {
                name: 'Princess Guard',
                description: 'Do [] damage 25% chance to add [] shield',
            },
            punchline: {
                name: 'Punchline',
                description: 'Do 5 damage',
            },
            quarterstaff: {
                name: 'Quarterstaff',
                description: 'Do [] damage, on 4, return the dice',
            },
            rat: {
                name: 'Rat',
                description: 'Inflict 1 poison, roll a new dice',
            },
            ray_gun: {
                name: 'Ray Gun',
                description: 'Do 3 damage',
            },
            rubber_mallet: {
                name: 'Rubber Mallet',
                description: 'Do [] damage, on 1, also reroll',
            },
            scissors: {
                name: 'Scissors',
                description: 'Do [] damage, split dice in two',
            },
            scorpion: {
                name: 'Scorpion',
                description: 'Do 20 damage',
            },
            seize: {
                name: 'Seize',
                description: 'Lock 1 dice',
            },
            shadow_dice: {
                name: 'Shadow Dice',
                description: 'Duplicate a dice Lose [] health',
            },
            sharp_spike: {
                name: 'Sharp Spike',
                description: 'Do 2 x [] damage',
            },
            short_sword: {
                name: 'Short Sword',
                description: 'Do [] damage',
            },
            silver_cauldron: {
                name: 'Silver Cauldron',
                description: 'Do 1 damage, get an odd dice',
            },
            silver_dagger: {
                name: 'Silver Dagger',
                description: 'Do [] damage, if odd, get a new dice',
            },
            singularity: {
                name: 'Singularity',
                description: 'Roll a one',
            },
            six_shooter: {
                name: 'Six Shooter',
                description: 'Do [] damage',
            },
            slime_ball: {
                name: 'Slime Ball',
                description: 'Add 2 poison',
            },
            slingshot: {
                name: 'Slingshot',
                description: 'Do 6 damage, reroll the dice',
            },
            small_shield: {
                name: 'Small Shield',
                description: 'Add [] shield',
            },
            smog_cloud: {
                name: 'Smog Cloud',
                description: 'Inflict 3 poison and 3 blind',
            },
            smush_together: {
                name: 'Smush Together',
                description: 'Combine dice, value -1',
            },
            snake_eye_charm: {
                name: 'Snake Eye Charm',
                description: 'Roll 2 ones',
            },
            snowball: {
                name: 'Snowball',
                description: 'Do [] damage, freeze 1 dice',
            },
            snowflake: {
                name: 'Snowflake',
                description: 'Freeze 1 dice',
            },
            spanner: {
                name: 'Spanner',
                description: 'Combine dice',
            },
            spannersword: {
                name: 'Spannersword',
                description: 'Combine dice, do 1 damage',
            },
            spark: {
                name: 'Spark',
                description: 'Burns 1 dice',
            },
            spatula: {
                name: 'Spatula',
                description: 'Flip a dice upside down',
            },
            spatulasword: {
                name: 'Spatulasword',
                description: 'Do [] damage, and flip the dice upside down',
            },
            spike: {
                name: 'Spike',
                description: 'Do 3 damage',
            },
            spiked_shield: {
                name: 'Spiked Shield',
                description: 'Even: [] damage Odd: [] shield',
            },
            splitula: {
                name: 'Splitula',
                description: 'Flip a dice upside down, duplicate the flipped dice',
            },
            square_pair: {
                name: 'Square Pair',
                description: 'Roll 2 fours',
            },
            staff: {
                name: 'Staff',
                description: 'Deal 6 damage, burn 1 dice',
            },
            subdivide: {
                name: 'Subdivide',
                description: 'Split a dice evenly',
            },
            sword: {
                name: 'Schwert',
                description: 'Macht [] Schaden',
            },
            sword_hilt: {
                name: 'Sword Hilt',
                description: 'Do [] damage',
            },
            target_shield: {
                name: 'Target Shield',
                description: 'Add [] to shield',
            },
            tetraphobia: {
                name: 'Tetraphobia',
                description: 'Do 4 damage',
            },
            the_ashes: {
                name: 'The Ashes',
                description: 'Do [] damage, burn 5 dice',
            },
            the_cold: {
                name: 'The Cold',
                description: 'Do [] damage, freeze 4 dice',
            },
            the_shadow: {
                name: 'The Shadow',
                description: 'Do [] damage, blind 3 dice',
            },
            the_wind: {
                name: 'The Wind',
                description: 'Do [] damage, lock 2 dice',
            },
            thick_skin: {
                name: 'Thick Skin',
                description: 'Add [] to shield, return the dice',
            },
            thunder_spell: {
                name: 'Thunder Spell',
                description: 'Deal 5 damage, shock 2 cards',
            },
            tower_shield: {
                name: 'Tower Shield',
                description: 'Add [] shield',
            },
            toxic_ooze: {
                name: 'Toxic Ooze',
                description: 'Do [] damage, on 6, add 2 poison',
            },
            two_handed_sword: {
                name: 'Two Handed Sword',
                description: 'Do [] damage',
            },
            venus_fly_trap: {
                name: 'Venus Fly Trap',
                description: 'Do [] damage, on 6, restore 2 health',
            },
            very_good_sword: {
                name: 'Very Good Sword',
                description: 'Do 2x[] damage',
            },
            whip: {
                name: 'Whip',
                description: 'Do [] damage, on 6, inflict 1 burn',
            },
            wicker_gemstaff: {
                name: 'Wicker Gemstaff',
                description: 'Deal 9 damage, iflict 2 freeze',
            },
            wicker_staff: {
                name: 'Wicker Staff',
                description: 'Deal 9 damage, burn 2 dice',
            },
            wind_up_fist: {
                name: 'Wind Up Fist',
                description: 'Do 5 damage',
            },
            wolf_puppy_bite: {
                name: 'Wolf Puppy Bite',
                description: 'Do 4 damage',
            },
            wooden_shield: {
                name: 'Wooden Shield',
                description: 'Add [] shield',
            },
            worm_bite: {
                name: 'Worm Bite',
                description: 'Do 1 damage',
            },
        },
        card_stats: {
            limitation: {
                even: 'Gerade',
                odd: 'Ungerade',
                min: 'Min',
                max: 'Max',
                not: 'Alles außer',
                only: 'Genau',
            }
        },
        info: {
            task_finished: 'Du hast die Aufgabe erfolgreich abgeschlossen!',
            reward: 'Deine Belohnung:',
            chase_successful: 'Du hast die Jagd erfolgreich abgeschlossen!',
            found_creeper: 'Du hast einen Creeper gefunden! Besiege ihn, um eine seiner Karten zu bekommen.',
            level_reached: 'Du hast Level {level} erreicht!',
            energy_and_melee_maxed: 'Deine Energie und Angriffe wurden wieder auf Maximum gesetzt!',
        },
        error: {
            passwords_do_not_match: 'Die Wiederholung stimmt nicht mit dem Passwort überein.',
            out_of_melee: 'Du hast keine Angriffe mehr frei!',
            level_too_low: 'Dein Level ist noch nicht hoch genug!',
            out_of_diamonds: 'Du hast nicht genügend Diamanten!',
            attack_level_range: 'Du kannst nur User mit deinem eigenen Level (+/- {level_range}) angreifen.',
            attack_time_limit: 'Du kannst diesen Gegner erst in {time_left} wieder zu einem Gold-Kampf herausfordern.',
        },
        energy_refill: {
            headline: 'Energie auffüllen',
            description: 'Fülle Deine Energie jetzt für 10 Diamanten wieder komplett auf.',
        },
        melee_refill: {
            headline: 'Angriffe auffüllen',
            description: 'Fülle Deine Angriffe jetzt für 10 Diamanten wieder komplett auf.',
        },
        menu: {
            landingpage: 'Castle Creepers',
            home: 'Home',
            creepers: 'Creepers',
            skills: 'Skills',
            ranking: 'Ranking',
            melee: 'Angriff',
            treasure: 'Treasure',
            premium: 'Premium',
        },
        stats: {
            treasure: 'Davon {treasure} im Treasure',
            ranking: 'Platzierung',
            level_experience: 'Experience',
        },
        newbie_ranking: {
            headline: 'Newbie-Ranking',
            description: 'Gewinne 150 Diamanten im Wert von 10 Euro!',
            description2: 'Erreiche als Erster Level 25, um die 150 Diamanten zu gewinnen.',
        },
        starter_package: {
            limit: 'Das Starter Paket ist nur bis Level 10 verfügbar!',
        },
        payment: {
            buy: 'Kaufen',
            transaction_successful: 'Kauf erfolgreich!',
            transation_diamonds_booked: 'Dir wurden die gekauften Diamanten gutgeschrieben!',
            transation_starter_package_booked: 'Dir wurde das gekaufte Starterpaket gutgeschrieben!',
            buy_starter_package: 'Dieses einmalige Angebot bekommst Du nicht wieder: Hol Dir {amount} Diamanten für {price} Euro - das ist ein Rabatt von 70%!',
            product: {
                diamonds30: '30 Diamanten',
                diamonds150: '150 Diamanten',
                diamonds500: '500 Diamanten',
                starterpackage: 'Starter Paket'
            }
        },
        landingpage: {
            login: 'Login',
            signup: 'Anmeldung',
        },
        login: {
            username: 'Username',
            password: 'Passwort',
            login: 'Einloggen',
        },
        signup: {
            username: 'Username',
            password: 'Passwort',
            password_repeat: 'Passwort Wiederholung',
            signup: 'Anmelden',
        },
        friends: {
            friends: 'Freunde',
            invite_friends: 'Einladen',
            friendship_requests: 'Freundschaftsanfragen',
            gifts: 'Geschenke',
            gift: {
                collect: {
                    message: 'Einsammeln'
                },
            },
            invite: {
                accept: 'Annehmen',
                deny: 'Ablehnen',
            }
        },
        chase: {
            costs: 'Kosten',
            bonus: 'Bonus',
            start: 'Starten',
            chance: 'Erfolgschance',
            show_book: 'Meine Creepers',
        },
        skills: {
            inventory: {
                name: 'Inventar',
                deck: 'Deck',
                backlog: 'Backlog',
                backlog_empty: 'Du hast keine Karten im Backlog.',
                upgrade: 'Upgrade',
            },
            limits: {
                name: 'Limits',
                points_available: 'Limitpoints verfügbar:',
            },
        },
        ranking: {
            name: 'Rangliste',
            melee: {
                title: 'Angriff',
                description: 'Besiege einen dieser Gegner, um seine Ranglistenposition einzunehmen.',
            },
            gold: {
                title: 'Gold',
                description: 'Besiege einen beliebigen Gegner, der den selben Level hat, um 10% von seinem Gold zu erobern.',
            }
        },
        melee: {
            vs: 'Matchup',
            dice: 'Würfel',
            hitpoints: 'Lebenspunkte',
            hint: {
                no_melee_left: 'Du hast keine Angriffe mehr übrig.',
            },
        },
        treasure: {
            balance: 'Guthaben:',
            transfer_in: 'Einzahlung ({fee}% Gebühr)',
            transfer_out: 'Auszahlung',
        },
        magic_treasure: {
            name: 'Magic Treasure',
            maximum: 'Du hast {current} / {max} Magic Treasures.',
            level: 'Magic Treasure Level {level}',
            costs: 'Kosten: {costs}',
            income: 'Einkommen / Stunde: {income}',
            amount: 'Anzahl: {amount}',
            sign: 'Abschließen',
            dismiss: 'Kündigen',
        },
        profile: {
            title: 'Profil',
            request_friendship: 'Freundschaftsanfrage stellen',
        },
        premium: {
            title: 'Premium',
            price: 'Preis: {diamonds} Diamanten',
            runtime: 'Laufzeit: {days} Tage',
            activate: 'Aktivieren',
            days_active: 'Paket ist noch {days} Tage aktiv',
            base: {
                title: 'Premium Base',
                short_title: 'Base',
                feature1: '30% mehr Experience',
                feature2: '30% schnellere Erneuerung der Angriffe',
                feature3: '30% schnellere Erneuerung der Energie',
                feature4: '20% weniger Abgabe bei Goldeinzahlungen',
            },
            money: {
                title: 'Premium Money',
                short_title: 'Money',
                feature1: '50% weniger Abgabe bei Goldeinzahlungen',
                feature2: 'Vorhandenes Gold wird automatisch jede Stunde in dein Treasure eingezahlt',
                feature3: '10% niedrigere Kosten bei Magic Treasures',
                feature4: 'Es kann direkt aus dem Treasure bezahlt werden',
            },
            melee: {
                title: 'Premium Angriff',
                short_title: 'Angriff',
                feature1: '30% höheres Angriffslimit',
                feature2: '10% höheres Energielimit',
                feature3: 'Goldkämpfe gegen Gegner mit bis zu 3 Leveln Unterschied möglich',
                feature4: '30 Minuten kürzere Wartezeit, um den selben Gegner im Goldkampf herauszufordern',
            },
            energy: {
                title: 'Premium Energie',
                short_title: 'Energie',
                feature1: '10% höheres Energielimit',
                feature2: '10% weniger Energie für die Jagd notwendig',
            },
            skills: {
                title: 'Premium Skills',
                short_title: 'Skills',
                feature1: '10% höheres Energielimit',
            },
        },
        task: {
            title: {
                end_chase: 'Beende die Jagd "%chase1%".',
                end_chases: 'Beende die Jagd "%chase1%" und "%chase2%".',
                find_one_common_creeper: 'Finde einen Common Creeper.',
                find_three_common_creepers: 'Finde 3 Common Creeper.',
                find_one_uncommon_creeper: 'Finde einen Uncommon Creeper.',
                find_one_rare_creeper: 'Finde einen Rare Creeper.',
                find_one_epic_creeper: 'Finde einen Epic Creeper.',
                find_one_legendary_creeper: 'Finde einen Legendary Creeper.',
                defeat_an_opponent_rank: 'Besiege einen Gegner in einem Ranglistenkampf.',
                defeat_an_opponent_gold: 'Besiege einen Gegner im Goldkampf.',
                activate_magic_treasure: 'Aktiviere ein Magic Treasure.',
                increase_melee_limit: 'Erhöhe dein Angriffs-Limit.',
                defeat_four_opponents: 'Besiege vier Gegner.',
                pay_to_account: 'Zahle 10000 Gold auf dein Konto ein.',
            },
        },
        biome: {
            marine: 'Marine',
            hot_desert: 'Hot Desert',
            cold_desert: 'Cold Desert',
            savanna: 'Savanna',
            grassland: 'Grassland',
            tropical_seasonal_forest: 'Tropical Seasonal Forest',
            temperate_deciduous_forest: 'Temperate Deciduous Forest',
            tropical_rainforest: 'Tropical Rainforest',
            temperate_rainforest: 'Temperate Rainforest',
            taiga: 'Taiga',
            tundra: 'Tundra',
            glacier: 'Glacier',
            wetland: 'Wetland',
        },
        levelup: {
            level2: {
                feature1: 'Du kannst ab sofort Dein Inventar verwalten.',
            },
            level3: {
                feature1: 'Du kannst ab sofort Kämpfe um dein Ranking bestreiten.',
            },
            level4: {
            },
            level5: {
                feature1: 'Du kannst ab sofort Gold-Angriffe bestreiten.',
                feature2: 'Du kannst ab sofort Gold in Dein Treasure einzahlen.',
            },
            level6: {
                feature1: 'Du kannst ab sofort Diamanten kaufen.',
                feature2: 'Du kannst ab sofort verschiedene Vorteile mit Diamanten erwerben.',
            },
            level7: {
                feature1: 'Du kannst ab sofort deine Limits erhöhen.',
                feature2: 'Du kannst ab sofort das Premium Paket "Energie" kaufen.',
            },
            level8: {
                feature1: 'Du kannst ab sofort Magic Treasures kaufen.',
                feature2: 'Du kannst ab sofort das Premium Paket "Money" kaufen.',
            },
            level9: {
                feature1: 'Du kannst ab sofort das Premium Paket "Skills" kaufen.',
            },
            level10: {
                feature1: 'Du kannst ab sofort das Premium Paket "Angriff" kaufen.',
            }
        }
    }
};

export default new VueI18n({
    locale: 'de',
    messages,
});
