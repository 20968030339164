<template>
    <div>
        <div>
            <label>
                <div>{{ $t('signup.username') }}</div>
                <div><cc-input v-model="username" /></div>
            </label>
        </div>
        <div class="mt1">
            <label>
                <div>{{ $t('signup.password') }}</div>
                <div>
                    <cc-input
                        v-model="password"
                        type="password"
                    />
                </div>
            </label>
        </div>
        <div class="mt1">
            <label>
                <div>{{ $t('signup.password_repeat') }}</div>
                <div>
                    <cc-input
                        v-model="passwordRepeat"
                        type="password"
                    />
                </div>
            </label>
        </div>
        <cc-button
            class="mt1"
            @click="signup()"
        >
            {{ $t('signup.signup') }}
        </cc-button>
    </div>
</template>

<script>
import Button from '../../component/Button.vue';
import UserRepository from "../../repository/UserRepository";
import platform from 'platform';
import Input from "../../component/Input";

export default {
    components: {
        'cc-button': Button,
        'cc-input': Input,
    },
    data() {
        return {
            username: '',
            password: '',
            passwordRepeat: '',
        }
    },
    methods: {
        signup() {
            if (this.password !== this.passwordRepeat) {
                window.eventbus.$emit('errors', [this.$t('error.passwords_do_not_match')]);
                return;
            }
            UserRepository
                .add(this.username, this.password)
                .then(() => {
                    UserRepository
                        .authenticate(this.username, this.password, platform.description)
                        .then(() => {
                            window.eventbus.$emit('start_game');
                            this.$router.push({ name: 'overview' });
                        });
                });
        },
    },
}
</script>
