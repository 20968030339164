<template>
    <cc-layer :headline="$t(headline)">
        <p>{{ $t(message) }}</p>
        <cc-buttonbar>
            <cc-button @click="execute()">
                {{ $t('button.buy') }}
            </cc-button>
            <cc-button @click="close()">
                {{ $t('button.cancel') }}
            </cc-button>
        </cc-buttonbar>
    </cc-layer>
</template>

<script>
import Button from '../Button.vue';
import Buttonbar from '../Buttonbar.vue';
import Layer from '../Layer.vue';

export default {
    components: {
        'cc-button': Button,
        'cc-buttonbar': Buttonbar,
        'cc-layer': Layer,
    },
    props: {
        headline: {
            required: true,
            type: String,
        },
        message: {
            type: String,
            required: true,
        },
        callback: {
            required: true,
        }
    },
    methods: {
        execute() {
            this.callback();
            this.close();
        },
        close() {
            this.$store.commit('removeLayer', this);
        }
    }
}
</script>
