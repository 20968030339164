<template>
    <cc-loading v-if="loading" />
    <div
        v-else
        class="subcontent"
    >
        <h3>{{ $t('skills.inventory.deck') }}</h3>
        <div class="cards">
            <div
                v-for="(inventoryItem, index) in inventoryDeck"
                :key="index"
                style="text-align: center"
            >
                <cc-card
                    style="cursor: pointer"
                    :name="inventoryItem.cardDefinition.name"
                    :usages="inventoryItem.cardDefinition.usages"
                    :slots="inventoryItem.cardDefinition.slots"
                    :dice="inventoryItem.cardDefinition.dice"
                    :hitpoints="inventoryItem.cardDefinition.hitpoints"
                    :types="inventoryItem.cardDefinition.types"
                    :level="inventoryItem.level"
                    :usable="inventoryItem.levelIsSufficient"
                    @click.native="moveToBacklog(inventoryItem)"
                />
                <cc-button @click="upgrade(inventoryItem)">
                    {{ $t('skills.inventory.upgrade') }}
                </cc-button>
            </div>
            <div
                v-for="i in 6 - inventoryDeck.length"
                :key="'deck_' + i"
                class="cardSlot"
            />
        </div>
        <h3>{{ $t('skills.inventory.backlog') }}</h3>
        <div class="cards">
            <div
                v-for="(inventoryItem, index) in inventoryBacklog"
                :key="'backlog_' + index"
                style="text-align: center"
            >
                <cc-card
                    :style="{ cursor }"
                    :name="inventoryItem.cardDefinition.name"
                    :usages="inventoryItem.cardDefinition.usages"
                    :slots="inventoryItem.cardDefinition.slots"
                    :dice="inventoryItem.cardDefinition.dice"
                    :hitpoints="inventoryItem.cardDefinition.hitpoints"
                    :types="inventoryItem.cardDefinition.types"
                    :level="inventoryItem.level"
                    :usable="inventoryItem.levelIsSufficient"
                    @click.native="moveToDeck(inventoryItem)"
                />
                <cc-button @click="upgrade(inventoryItem)">
                    {{ $t('skills.inventory.upgrade') }}
                </cc-button>
            </div>
            <div v-if="inventoryBacklog.length === 0">
                {{ $t('skills.inventory.backlog_empty') }}
            </div>
        </div>
    </div>
</template>

<script>
import Loading from '../../component/Loading.vue';
import Card from "../../component/Card";
import InventoryRepository from "../../repository/InventoryRepository";
import Button from "../../component/Button";

export default {
    components: {
        'cc-loading': Loading,
        'cc-card': Card,
        'cc-button': Button,
    },
    data() {
        return {
            loading: true,
            inventory: [],
        }
    },
    computed: {
        inventoryDeck() {
            return this.inventory.filter(inventoryItem => inventoryItem.isInDeck);
        },
        inventoryBacklog() {
            return this.inventory.filter(inventoryItem => !inventoryItem.isInDeck);
        },
        cursor() {
            return this.inventoryDeck.length < 6 ? 'pointer' : 'default'
        },
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            InventoryRepository.list().then(inventory => {
                this.inventory = inventory;
                this.loading = false;
            });
        },
        moveToBacklog(inventoryItem) {
            this.toggle(inventoryItem.id);
        },
        moveToDeck(inventoryItem) {
            if (this.inventoryDeck.length >= 6) {
                return;
            }

            this.toggle(inventoryItem.id);
        },
        upgrade(inventoryItem) {
            InventoryRepository.upgrade(inventoryItem.id).then(() => this.load());
        },
        toggle(id) {
            InventoryRepository.toggle(id).then(() => this.load());
        },
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.cards {
    padding: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    .card {
        margin: 12px;
    }
    .button {
        margin-bottom: 32px;
    }
}
.cardSlot {
    width: 256px;
    height: 358px;
    border: 2px dotted #140601;
    margin: 12px;
    border-radius: 8px;
}
</style>
