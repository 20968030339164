<template>
    <cc-side-box
        :headline="$t('newbie_ranking.headline')"
        :note="$t('newbie_ranking.description2')"
    >
        <p>{{ $t('newbie_ranking.description') }}</p>
        <table>
            <thead>
                <tr>
                    <th class="left">
                        {{ $t('rank') }}
                    </th>
                    <th class="left">
                        {{ $t('username') }}
                    </th>
                    <th class="left">
                        {{ $t('general.level') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(user, index) in users"
                    :key="index"
                >
                    <td>{{ (index + 1) }}.</td>
                    <td>
                        <router-link
                            :to="{ name: 'profile', params: { id: user.id }}"
                            class="user"
                        >
                            {{ user.username }}
                        </router-link>
                    </td>
                    <td>{{ user.level }}</td>
                </tr>
            </tbody>
        </table>
    </cc-side-box>
</template>

<script>
import UserRepository from "../repository/UserRepository";

import SideBox from './SideBox.vue';

export default {
    components: {
        'cc-side-box': SideBox,
    },
    data() {
        return {
            users: [],
        }
    },
    created() {
        UserRepository.findNewbieRanking().then(users => this.users = users);
    }
}
</script>
