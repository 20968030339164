<template>
    <v-app-bar
        app
        clipped-left
        clipped-right
        flat
        :class="classes"
    >
        <template v-if="$store.getters.isAuthenticated">
            <cc-icon
                type="gold"
                :description="$t('gold')"
            >
                <cc-dynamic-number :value="user.gold" />
            </cc-icon>
            <cc-icon
                type="experience"
                :description="$t('stats.level_experience')"
            >
                <template #annotation>
                    <cc-dynamic-number :value="user.experience" /> / {{ user.experienceNextLevel }}
                </template>
                <cc-dynamic-number :value="user.level" />
            </cc-icon>
            <cc-icon
                type="diamond"
                :description="$t('diamond')"
            >
                <cc-dynamic-number :value="user.diamonds" />
                <router-link
                    v-if="user.level >= 6"
                    slot="refill"
                    :to="{ name: 'diamonds' }"
                >
                    {{ $t('general.refill') }}
                </router-link>
            </cc-icon>
            <cc-icon
                type="energy"
                :description="$t('energy')"
            >
                <template #annotation>
                    {{ timer(user.energyTimer) }}
                </template>
                <cc-dynamic-number :value="user.energy" /> / <cc-dynamic-number :value="user.maxEnergy" />
                <a
                    v-if="user.energy < user.maxEnergy"
                    slot="refill"
                    @click="showDiamondEnergyLayer()"
                >{{ $t('general.refill') }}</a>
            </cc-icon>
            <cc-icon
                type="ranking"
                :description="$t('stats.ranking')"
            >
                <cc-dynamic-number
                    :value="user.ranking"
                    :inverted="true"
                />.
            </cc-icon>
            <cc-icon
                type="melee"
                :description="$t('general.melee')"
            >
                <template #annotation>
                    {{ timer(user.meleeTimer) }}
                </template>
                <cc-dynamic-number :value="user.currentMelee" /> / <cc-dynamic-number :value="user.maxMelee" />
                <a
                    v-if="user.currentMelee < user.maxMelee"
                    slot="refill"
                    @click="showDiamondMeleeLayer()"
                >
                    {{ $t('general.refill') }}
                </a>
            </cc-icon>
        </template>
    </v-app-bar>
</template>

<script>
import Icon from "./Icon";
import DynamicNumber from "./DynamicNumber";
import BuyEnergyLayer from "../model/dto/layer/BuyEnergyLayer";
import BuyMeleeLayer from "../model/dto/layer/BuyMeleeLayer";

export default {
    components: {
        'cc-icon': Icon,
        'cc-dynamic-number': DynamicNumber,
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        classes() {
            return {
                mobile: this.isMobile,
            }
        },
        isMobile() {
            return this.$vuetify.breakpoint.width < 1264;
        },
    },
    methods: {
        timer(seconds) {
            if (seconds === 0) {
                return '';
            }

            let minutes = 0;
            if (seconds >= 60) {
                minutes = Math.floor(seconds / 60);
                seconds -= minutes * 60;
            }
            if (minutes < 10) {
                minutes = '0' + minutes;
            }
            if (seconds < 10) {
                seconds = '0' + seconds;
            }

            return '(' + minutes + ':' + seconds + ')';
        },
        showDiamondEnergyLayer() {
            this.$store.commit('addLayer', new BuyEnergyLayer());
        },
        showDiamondMeleeLayer() {
            this.$store.commit('addLayer', new BuyMeleeLayer());
        }
    }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.v-app-bar {
    border: solid transparent;
    border-width: 26px 28px 29px;
    border-image-source: url("../../images/app_bar.png");
    border-image-repeat: repeat;
    border-image-slice: 26 28 29;
    background: url("../../images/app_bar_background.png") repeat content-box;
    color: $color-text-light;
    margin: 0 4px;
    height: 84px !important;
}
</style>

<style rel="stylesheet/scss" lang="scss">
.v-app-bar {
    .v-toolbar__content {
        margin: -26px 0 -4px;
        justify-content: center;
        padding: 0;
        .icon_container {
            width: 200px;
        }
    }
    &.mobile {
        .v-toolbar__content {
            margin-top: -20px;
        }
    }
}
</style>
