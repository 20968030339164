<template>
    <div
        class="card"
        @click="unshock()"
    >
        <div
            class="container"
            :style="style"
        >
            <div class="card-title">
                {{ $t('card.' + name + '.name') }}
            </div>
            <div
                v-if="level"
                class="level"
            >
                {{ level }}
            </div>
            <cc-card-types
                :damage="types.damage"
                :shield="types.shield"
                :health="types.health"
                :dice-modifier="types.diceModifier"
            />
            <div class="extras">
                <div
                    v-if="hitpoints > 0"
                    class="hitpoints"
                >
                    {{ hitpoints }}
                </div>
                <cc-die
                    v-for="i in dice"
                    :key="'dice_' + i"
                    :face="6"
                    small
                />
            </div>
            <div class="slots">
                <cc-card-slot
                    v-for="(slot, index) in slots"
                    :key="index"
                    :params="slot"
                    :selected-dice="diceToUse"
                    @accept="$emit('accept', index)"
                />
            </div>
            <div class="usages">
                <span v-if="usages === null">&infin;</span>
                <span v-if="usages === 0">-</span>
                <span v-if="usages !== null && usages !== 0">{{ usages }}x</span>
            </div>
            <div class="description">
                <cc-card-description :name="name" />
            </div>
        </div>
        <div
            v-if="shocked"
            class="shocked"
            :class="{ clickable: selectedDice !== null }"
        >
            <i class="flaticon-dice-agility" />
        </div>
    </div>
</template>

<script>
import CardSlot from "./CardSlot";
import CardDescription from "./CardDescription";
import CardTypes from "./CardTypes";
import Die from "./Die";

export default {
    components: {
        'cc-card-slot': CardSlot,
        'cc-card-description': CardDescription,
        'cc-card-types': CardTypes,
        'cc-die': Die,
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        usages: {
            type: Number,
            required: false,
            default: null,
        },
        slots: {
            type: Array,
            required: true,
        },
        dice: {
            type: Number,
            required: true,
        },
        selectedDice: {
            type: Number,
            required: false,
            default: null,
        },
        usable: {
            type: Boolean,
            required: true,
        },
        level: {
            type: Number,
            required: false,
            default: null,
        },
        types: {
            type: Object,
            required: true,
        },
        hitpoints: {
            type: Number,
            required: true,
        },
        shocked: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        style() {
            let image = require('../../images/' + (this.level ? 'card_with_level.png' : 'card.png'));
            return {
                opacity: this.usable && !this.shocked ? 1.0 : 0.3,
                backgroundImage: 'url(' + image + ')',
            }
        },
        diceToUse() {
            if (!this.usable) {
                return null;
            }

            if (this.shocked) {
                return null;
            }

            return this.selectedDice;
        },
    },
    methods: {
        unshock() {
            if (!this.shocked) {
                return;
            }

            if (this.selectedDice === null) {
                return;
            }

            this.$emit('unshock', this);
        },
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.card {
    width: 256px;
    height: 358px;
    color: white;
    position: relative;
    transition:
        opacity 0.5s ease-out;
    .container {
        width: 100%;
        height: 100%;
        .card-title {
            font-size: 1.5rem;
            color: #ffe8d0;
            position: absolute;
            top: 209px;
            width: 100%;
            text-align: center;
            transform: rotate(-2deg);
        }
        .description {
            position: absolute;
            top: 250px;
            width: 100%;
            height: 88px;
            padding: 0 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #140601;
            font-weight: bold;
            text-align: center;
        }
        .level {
            position: absolute;
            left: 0;
            bottom: 0;
            font-size: 1.5rem;
            width: 48px;
            height: 64px;
            color: #ffe8d0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .slots {
            position: absolute;
            top: 72px;
            display: flex;
            width: 100%;
            justify-content: space-around;
        }
        .usages {
            position: absolute;
            right: 0;
            bottom: 0;
            font-size: 1.5rem;
            width: 48px;
            height: 64px;
            color: #ffe8d0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .types {
            .type {
                display: block;
                visibility: hidden;
                width: 38px;
                height: 38px;
                &.active {
                    visibility: visible;
                }
            }
        }
        .extras {
            position: absolute;
            top: 0;
            right: 0;
            .hitpoints {
                width: 24px;
                height: 24px;
                background: #a30101;
                margin: 8px auto;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                border: 1px solid #460402;
            }
        }
    }
    .shocked {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        font-size: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #140601;
        &.clickable {
            cursor: pointer;
        }
    }
}
</style>
