<template>
    <div>
        <cc-avatar
            v-if="fighter.avatar"
            :side="side"
            :gender="fighter.avatar.gender"
            :type="fighter.avatar.type"
        />
        <cc-creeper-battle
            v-if="fighter.creeper"
            :name="fighter.creeper.name"
            :image="fighter.creeper.image"
            :frequency="fighter.creeper.frequency"
        />
    </div>
</template>

<script>
import Avatar from "../Avatar";
import CreeperBattle from "../CreeperBattle";

export default {
    components: {
        'cc-avatar': Avatar,
        'cc-creeper-battle': CreeperBattle,
    },
    props: {
        fighter: {
            required: true,
            type: Object,
        },
        side: {
            required: false,
            type: String,
            default: "left",
        }
    },
}
</script>
