<template>
    <canvas
        ref="canvas"
        :width="900"
        :height="700"
    />
</template>

<script>
import riversData from '../../map/data/rivers.json';
import routesData from '../../map/data/routes.json';
import burgData from '../../map/data/burgs.csv';
import CellDrawer from "../../map/Drawer/CellDrawer";
import Context from "../../map/Context";
import RiverParser from "../../map/Parser/RiverParser";
import RiverDrawer from "../../map/Drawer/RiverDrawer";
import RouteDrawer from "../../map/Drawer/RouteDrawer";
import RouteParser from "../../map/Parser/RouteParser";
import BurgDrawer from "../../map/Drawer/BurgDrawer";
import BurgParser from "../../map/Parser/BurgParser";
import Coordinate from "../../map/Coordinate";
import CellRepository from "../../map/Repository/CellRepository";

export default {
    data() {
        return {
            canvas: null,
            zoomFactor: 3,
            offset: [450, 260],
            pan: [0, 0],
            currentCell: null,
            targetCell: null,
        }
    },
    computed: {
        path() {
            if (this.targetCell === null || this.targetCell.id === this.currentCell.id) {
                return [];
            }

            return [this.currentCell.getCenter(), this.targetCell.getCenter()];
        }
    },
    created() {
        window.eventbus.$on('travel_completed', () => CellRepository.refresh());
        CellRepository.findById(this.$store.state.user.cell).then(cell => this.currentCell = cell);
    },
    mounted() {
        requestAnimationFrame(this.render);
        this.canvas = this.$refs.canvas;
        this.canvas.addEventListener('wheel', this.zoom);
        this.canvas.addEventListener('mousedown', this.startPan);
        this.canvas.addEventListener('mouseup', this.endPan);
        this.canvas.addEventListener('mouseleave', this.endPan);
        this.canvas.addEventListener('click', this.click);
    },
    methods: {
        render() {
            const context = new Context(this.canvas.getContext('2d'));

            context.context.setTransform(1, 0, 0, 1, 0, 0);
            context.clear();
            context.context.translate(this.offset[0], this.offset[1]);
            context.context.scale(this.zoomFactor, this.zoomFactor);
            CellRepository.findVisible().then(cells => {
                new RiverParser().parse(riversData).forEach(river => new RiverDrawer(context).draw(river));
                cells.forEach(cell => new CellDrawer(context).draw(cell));
                new CellDrawer(context).drawCurrent(this.currentCell);
                if (this.targetCell !== null && this.targetCell.id !== this.currentCell.id) {
                    new CellDrawer(context).drawHighlighted(this.targetCell);
                }

                new RouteParser().parse(routesData).forEach(route => new RouteDrawer(context).draw(route));
                new BurgParser().parse(burgData).forEach(burg => new BurgDrawer(context).draw(burg));

                CellRepository.findNotVisible().then(cells => cells.forEach(cell => new CellDrawer(context).drawBlack(cell)));

                if (this.path.length > 0) {
                    context.beginPath();
                    context.moveTo(this.path[0]);
                    this.path.forEach(waypoint => context.lineTo(waypoint));
                    context.stroke('red', 1 / 6);
                }
            });

            requestAnimationFrame(this.render)
        },
        startPan(event) {
            this.$refs.canvas.addEventListener('mousemove', this.doPan);
            this.pan = [event.clientX, event.clientY];
        },
        endPan() {
            this.$refs.canvas.removeEventListener('mousemove', this.doPan);
        },
        doPan(event) {
            this.offset[0] += event.clientX - this.pan[0];
            this.offset[1] += event.clientY - this.pan[1];
            this.pan[0] = event.clientX;
            this.pan[1] = event.clientY;
        },
        zoom(event) {
            const zoomDiff = Math.sign(event.deltaY);
            const x = (event.offsetX - this.offset[0]) / this.zoomFactor;
            const y = (event.offsetY - this.offset[1]) / this.zoomFactor;
            this.zoomFactor = Math.min(30, Math.max(3, this.zoomFactor - zoomDiff));
            this.offset[0] += -(x * -zoomDiff);
            this.offset[1] += -(y * -zoomDiff);
            event.preventDefault();
        },
        click(event) {
            const coordinate = new Coordinate((event.offsetX - this.offset[0]) / this.zoomFactor, (event.offsetY - this.offset[1]) / this.zoomFactor);

            CellRepository.findByCoordinate(coordinate).then(cell => {
                this.targetCell = cell;
                this.$emit('targetChanged', this.targetCell);
            });
        },
    },
}
</script>

<style scoped>
</style>
