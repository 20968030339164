<template>
    <div class="logo" />
</template>

<script>
export default {
}
</script>

<style>
.logo {
    width: 380px;
    height: 226px;
    background-image: url("../../images/logo.png");
}
</style>
