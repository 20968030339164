<template>
    <div>
        <cc-loading v-if="loading" />
        <div v-else>
            <div class="subcontent">
                <table>
                    <caption class="mb1">
                        {{ $t('ranking.melee.description') }}
                    </caption>
                    <tbody>
                        <tr
                            v-for="(user, index) in meleeRanking"
                            :key="index"
                        >
                            <td class="right">
                                {{ user.ranking }}.
                            </td>
                            <td>
                                <cc-avatar-portrait
                                    :gender="user.avatarGender"
                                    :type="user.avatarType"
                                />
                            </td>
                            <td>
                                <div class="username">
                                    <router-link
                                        :to="{ name: 'profile', params: { id: user.id }}"
                                        class="user"
                                    >
                                        {{ user.username }}
                                    </router-link>
                                </div>
                                <div class="level">
                                    {{ $t('general.level') }} {{ user.level }}
                                </div>
                            </td>
                            <td class="right">
                                <cc-button
                                    v-if="$store.state.user.id !== user.id"
                                    @click="$router.push({ name: 'matchup', params: { type: 'ranking', id: user.id } })"
                                >
                                    {{ $t('button.attack') }}
                                </cc-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <cc-disabled :level="3" />
            </div>
        </div>
    </div>
</template>

<script>
import AvatarPortrait from '../../component/AvatarPortrait.vue';
import Button from '../../component/Button.vue';
import Disabled from '../../component/Disabled.vue';
import Loading from '../../component/Loading.vue';

import UserRepository from "../../repository/UserRepository";

export default {
    components: {
        'cc-avatar-portrait': AvatarPortrait,
        'cc-button': Button,
        'cc-disabled': Disabled,
        'cc-loading': Loading,
    },
    data() {
        return {
            meleeRanking: [],
            loading: true,
        }
    },
    created() {
        UserRepository.findMeleeRanking().then(ranking => {
            this.meleeRanking = ranking;
            this.loading = false;
        });
    }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../../css/variables";

table {
    tr:not(:last-of-type) {
        td {
            border-bottom: 1px solid $color-line-seperator;
        }
    }
}
.avatar {
    margin: 0 auto;
}
.username {
    font-size: 21px;
}
.level {
    font-size: 13px;
}
</style>
