<template>
    <v-card
        class="frame_big"
        tile
        :elevation="0"
    >
        <div
            class="frame_headline"
            :style="headlineContainerStyles"
        >
            <h2 :style="headlineStyles">
                {{ headline }}
            </h2>
        </div>
        <div class="frame_content">
            <slot />
        </div>
    </v-card>
</template>

<script>
export default {
    props: {
        headline: {
            required: true,
            type: String,
        },
    },
    computed: {
        headlineContainerStyles() {
            return {
                marginTop: (this.isMobile ? -118 : -124) + 'px',
            }
        },
        headlineStyles() {
            return {
                width: (this.isMobile ? 110 : 220) + 'px',
                height: (this.isMobile ? 21 : 42) + 'px',
                fontSize: (this.isMobile ? 8 : 15) + 'px',
                paddingTop: (this.isMobile ? 4 : 10) + 'px',
            }
        },
        isMobile() {
            return this.$vuetify.breakpoint.width < 1264;
        },
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.v-card {
    background: transparent;
}
.frame_big {
    border: 124px solid transparent;
    border-top-width: 118px;
    border-bottom-width: 58px;
    border-image: url("../../images/frame_big.png") 118 124 58 fill repeat;
    position: relative;
    color: $color-text-dark;
    max-width: unset;
    margin: 0 -8px;
    .frame_headline {
        margin: 0 -124px;
        h2 {
            margin: 0 auto;
            color: $color-text-light;
            background-image: url("../../images/headline.png");
            background-size: 100%;
            text-align: center;
            text-transform: uppercase;
        }
    }
    .frame_content {
        margin: 32px -93px -48px;
        padding: 16px;
    }
}
</style>
