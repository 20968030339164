<template>
    <div style="display: flex; flex-wrap: wrap; align-items: flex-start; justify-content: space-between">
        <cc-task v-if="user.task && user.task.order > -1" />
        <cc-starter-package v-if="user.level <= 10" />
        <cc-newbie-ranking />
    </div>
</template>

<script>
import NewbieRanking from '../../component/NewbieRanking.vue';
import StarterPackage from '../../component/StarterPackage.vue';
import Task from '../../component/Task.vue';

export default {
    components: {
        'cc-newbie-ranking': NewbieRanking,
        'cc-starter-package': StarterPackage,
        'cc-task': Task,
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../../css/variables";
</style>
