<template>
    <div class="subcontent">
        <h4>{{ $t('premium.' + type + '.title') }}</h4>
        <ul>
            <li
                v-for="index in features"
                :key="index"
            >
                {{ $t('premium.' + type + '.feature' + index) }}
            </li>
        </ul>
        <div style="display: flex">
            <cc-button @click="buy()">
                {{ $t('premium.activate') }}
            </cc-button>
            <div class="ml2">
                <div>{{ $t('premium.price', { diamonds: 30 }) }}</div>
                <div>{{ $t('premium.runtime', { days: 14 }) }}</div>
            </div>
        </div>
        <div v-if="daysLeft > 0">
            {{ $t('premium.days_active', { days: daysLeft }) }}
        </div>
        <cc-disabled :level="level" />
    </div>
</template>

<script>
import Button from './Button.vue';
import Disabled from './Disabled.vue';

import PremiumRepository from "../repository/PremiumRepository";

export default {
    components: {
        'cc-button': Button,
        'cc-disabled': Disabled,
    },
    props: {
        type: {
            required: true,
            type: String,
        },
        features: {
            required: true,
            type: Number,
        },
        daysLeft: {
            required: true,
            type: Number,
        },
        level: {
            required: true,
            type: Number,
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    },
    methods: {
        buy() {
            let errors = [];
            if (this.user.level < this.level) {
                errors.push(this.$t('error.level_too_low'));
            }
            if (this.user.diamonds < 30) {
                errors.push(this.$t('error.out_of_diamonds'));
            }

            if (errors.length > 0) {
                window.eventbus.$emit('errors', errors);
                return;
            }

            PremiumRepository.create(this.type);
        }
    },
}
</script>
