<template>
    <cc-frame-big :headline="$t('menu.creepers')">
        <cc-loading v-if="loading" />
        <div v-else>
            <cc-button @click="showMap = !showMap">
                toggle map
            </cc-button>
            <div
                v-if="showMap"
                style="display: flex"
            >
                <cc-map @targetChanged="target = $event" />
                <cc-map-target
                    v-if="target"
                    :cell="target"
                    @move="move()"
                />
            </div>
            <div
                v-for="(chase, index) in chases"
                v-show="isSelected(chase)"
                :key="index"
            >
                <div class="chase-info">
                    <div>
                        <h3>{{ $t('chase.costs') }}</h3>
                        <cc-icon
                            type="energy"
                            :description="$t('energy')"
                        >
                            {{ chase.requirementEnergy }}
                        </cc-icon>
                    </div>
                    <div>
                        <h3>{{ $t('chase.bonus') }}</h3>
                        <cc-icon
                            type="gold"
                            :description="$t('gold')"
                        >
                            {{ goldReward(chase) }}
                        </cc-icon>
                        <cc-icon
                            type="experience"
                            :description="$t('experience')"
                        >
                            {{ experienceReward(chase) }}
                        </cc-icon>
                    </div>
                    <div>
                        <h3>{{ $t('chase.chance') }}</h3>
                        <cc-icon
                            v-if="chase.chanceSchedule.common > 0"
                            type="common"
                            :description="$t('frequency.common')"
                        >
                            {{ chase.chanceSchedule.common }}%
                        </cc-icon>
                        <cc-icon
                            v-if="chase.chanceSchedule.uncommon > 0"
                            type="uncommon"
                            :description="$t('frequency.uncommon')"
                        >
                            {{ chase.chanceSchedule.uncommon }}%
                        </cc-icon>
                        <cc-icon
                            v-if="chase.chanceSchedule.rare > 0"
                            type="rare"
                            :description="$t('frequency.rare')"
                        >
                            {{ chase.chanceSchedule.rare }}%
                        </cc-icon>
                        <cc-icon
                            v-if="chase.chanceSchedule.epic > 0"
                            type="epic"
                            :description="$t('frequency.epic')"
                        >
                            {{ chase.chanceSchedule.epic }}%
                        </cc-icon>
                        <cc-icon
                            v-if="chase.chanceSchedule.legendary > 0"
                            type="legendary"
                            :description="$t('frequency.legendary')"
                        >
                            {{ chase.chanceSchedule.legendary }}%
                        </cc-icon>
                    </div>
                    <div class="button_container">
                        <div>
                            <cc-buttonbar>
                                <cc-button @click="start(chase)">
                                    {{ $t('chase.start') }}
                                </cc-button>
                            </cc-buttonbar>
                            <cc-buttonbar>
                                <cc-button
                                    stop
                                    @click="showBook()"
                                >
                                    {{ $t('chase.show_book') }}
                                </cc-button>
                            </cc-buttonbar>
                        </div>
                    </div>
                </div>
                <div class="image_container">
                    <div class="arrow_container">
                        <cc-arrow-previous
                            v-show="selectedChaseIndex > 0"
                            @click="previous()"
                        />
                    </div>
                    <div
                        class="image"
                        :style="{ backgroundImage: 'url(' + require('../../images/chase/' + (selectedChaseIndex + 1) + '.png') + ')' }"
                    />
                    <div class="arrow_container">
                        <cc-arrow-next
                            v-show="selectedChaseIndex < chases.length - 1"
                            @click="next()"
                        />
                    </div>
                </div>
            </div>
            <cc-book
                v-show="isBookVisible"
                @close="isBookVisible = false"
            />
        </div>
    </cc-frame-big>
</template>

<script>
import ChaseRepository from "../repository/ChaseRepository";
import UserRepository from "../repository/UserRepository";
import BuyEnergyLayer from "../model/dto/layer/BuyEnergyLayer";

import ArrowNext from '../component/ArrowNext.vue';
import ArrowPrevious from '../component/ArrowPrevious.vue';
import Book from '../component/Book.vue';
import Button from '../component/Button.vue';
import Buttonbar from '../component/Buttonbar.vue';
import Icon from '../component/Icon.vue';
import Loading from '../component/Loading.vue';
import Map from "../component/Map";
import FrameBig from "../component/FrameBig";
import MapTarget from "../component/MapTarget";
import axios from "../axios";
import CellRepository from "../../map/Repository/CellRepository";

export default {
    components: {
        'cc-arrow-next': ArrowNext,
        'cc-arrow-previous': ArrowPrevious,
        'cc-book': Book,
        'cc-button': Button,
        'cc-buttonbar': Buttonbar,
        'cc-icon': Icon,
        'cc-loading': Loading,
        'cc-map': Map,
        'cc-frame-big': FrameBig,
        'cc-map-target': MapTarget,
    },
    data() {
        return {
            chases: [],
            selectedChaseIndex: 0,
            isBookVisible: false,
            loading: true,
            showMap: false,
            target: null,
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
    },
    created() {
        ChaseRepository.list().then(chases => {
            this.chases = chases;
            this.loading = false;
        });
        CellRepository.findById(this.$store.state.user.cell).then(cell => this.target = cell);
    },
    methods: {
        start(chase) {
            if (this.user.energy < chase.requirementEnergy) {
                this.$store.commit('addLayer', new BuyEnergyLayer());
                return;
            }

            ChaseRepository
                .start(chase.id)
                .then(() => {
                    ChaseRepository.list().then(chases => this.chases = chases);
                    UserRepository.findMe().then(user => this.$store.commit('loadUser', user));
                });
        },
        next() {
            this.selectedChaseIndex++;
        },
        previous() {
            this.selectedChaseIndex--;
        },
        isSelected(chase) {
            return this.chases[this.selectedChaseIndex].id === chase.id;
        },
        goldReward(chase) {
            if (chase.reward.goldMin === chase.reward.goldMax) {
                return chase.reward.goldMin;
            }

            return chase.reward.goldMin + ' - ' + chase.reward.goldMax;
        },
        experienceReward(chase) {
            if (chase.reward.experienceMin === chase.reward.experienceMax) {
                return chase.reward.experienceMin;
            }

            return chase.reward.experienceMin + ' - ' + chase.reward.experienceMax;
        },
        showBook() {
            this.isBookVisible = true;
        },
        move() {
            axios
                .post('/travels', { cellId: this.target.id })
                .then(() => {
                    window.eventbus.$emit('travel_completed');
                    UserRepository.findMe().then(user => this.$store.commit('loadUser', user));
                });
        }
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.chase-info {
    display: flex;
    justify-content: space-around;
    height: 180px;
    .button_container {
        display: flex;
        align-items: center;
    }
}
.image_container {
    margin: 0 auto;
    display: flex;
    align-items: center;
    .image {
        width: 790px;
        height: 444px;
    }
    .arrow_container {
        width: 30px;
        .arrow_previous {
            margin-right: auto;
        }
        .arrow_next {
            margin-left: auto;
        }
    }
}
</style>
