import Coordinate from "./Coordinate";

export default class Cell {

    constructor(id, type, biome, polygon, creepers) {
        this.id = id;
        this.visible = false;
        this.type = type;
        this.biome = biome;
        this.polygon = polygon;
        this.creepers = creepers;
    }

    makeVisible() {
        this.visible = true;
    }

    getColor() {
        if (this.type === 'ocean') {
            return 'rgb(31, 120, 180)';
        }

        return this.biome.getColor();
    }

    getBorderColor() {
        if (this.type === 'ocean') {
            return 'hsl(204, 71%, 31%)';
        }

        return this.biome.getBorderColor();
    }

    contains(coordinate) {
        const x = coordinate.x;
        const y = coordinate.y;
        let contains = false;
        for (let i = 0, j = this.polygon.length - 1; i < this.polygon.length; j = i++) {
            let xi = this.polygon[i].x;
            let yi = this.polygon[i].y;
            let xj = this.polygon[j].x;
            let yj = this.polygon[j].y;

            let intersect = (yi > y) !== (yj > y) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) {
                contains = !contains;
            }
        }

        return contains;
    }

    getCenter() {
        let signedArea = 0;
        let center = new Coordinate(0, 0);
        for (let i = 0; i < this.polygon.length; i++) {
            let x0 = this.polygon[i].x;
            let y0 = this.polygon[i].y;
            let x1 = this.polygon[(i + 1) % this.polygon.length].x;
            let y1 = this.polygon[(i + 1) % this.polygon.length].y;
            let a = x0 * y1 - x1 * y0;
            signedArea += a;
            center.x += (x0 + x1) * a;
            center.y += (y0 + y1) * a;
        }

        signedArea *= 0.5;
        center.x /= (6.0 * signedArea);
        center.y /= (6.0 * signedArea);

        return center;
    }
}
