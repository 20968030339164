<template>
    <cc-frame-big :headline="$t('premium.title')">
        <cc-tabs>
            <cc-tab
                :route="{ name: 'premium_base' }"
                :label="$t('premium.base.short_title')"
            />
            <cc-tab
                :route="{ name: 'premium_money' }"
                :label="$t('premium.money.short_title')"
            />
            <cc-tab
                :route="{ name: 'premium_melee' }"
                :label="$t('premium.melee.short_title')"
            />
            <cc-tab
                :route="{ name: 'premium_energy' }"
                :label="$t('premium.energy.short_title')"
            />
            <cc-tab
                :route="{ name: 'premium_skills' }"
                :label="$t('premium.skills.short_title')"
            />
        </cc-tabs>

        <router-view />
    </cc-frame-big>
</template>

<script>
import Tabs from '../component/Tabs.vue';
import Tab from '../component/Tab.vue';
import FrameBig from "../component/FrameBig";

export default {
    components: {
        'cc-frame-big': FrameBig,
        'cc-tabs': Tabs,
        'cc-tab': Tab,
    },
}
</script>
