<template>
    <div
        class="slot"
        :class="{ accepts: acceptsSelectedDice }"
        @click="dropDice()"
    >
        <div
            v-if="type === 'simple'"
            class="simple"
        >
            <div v-if="limitation.type === 'even'">
                {{ $t('card_stats.limitation.even') }}
            </div>
            <div v-if="limitation.type === 'odd'">
                {{ $t('card_stats.limitation.odd') }}
            </div>
            <div v-if="limitation.type === 'min'">
                {{ $t('card_stats.limitation.min') }}
            </div>
            <div v-if="limitation.type === 'max'">
                {{ $t('card_stats.limitation.max') }}
            </div>
            <div v-if="limitation.type === 'not'">
                {{ $t('card_stats.limitation.not') }}
            </div>
            <div v-if="limitation.type === 'only'">
                {{ $t('card_stats.limitation.only') }}
            </div>
            <div v-if="limitation.params.length > 0">
                {{ limitation.params.join('|') }}
            </div>
        </div>
        <div
            v-if="type === 'countdown'"
            class="countdown"
        >
            {{ counter }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        params: {
            type: String,
            required: true,
        },
        selectedDice: {
            type: Number,
            required: false,
            default: null,
        },
    },
    computed: {
        type() {
            return this.parts[0];
        },
        limitation() {
            return {
                type: this.parts[1],
                params: this.parts[2] ? this.parts.slice(2) : [],
            }
        },
        counter() {
            return this.parts[1];
        },
        parts() {
            return this.params.split('|');
        },
        acceptsSelectedDice() {
            if (this.selectedDice === null) {
                return false;
            }
            if (this.type === 'countdown') {
                return true;
            }
            if (this.type === 'simple') {
                switch (this.limitation.type) {
                    case 'no':
                        return true;
                    case 'max':
                        return this.selectedDice <= this.limitation.params[0];
                    case 'min':
                        return this.selectedDice >= this.limitation.params[0];
                    case 'only':
                        return this.selectedDice === parseInt(this.limitation.params[0]);
                    case 'not':
                        return this.selectedDice !== parseInt(this.limitation.params[0]);
                    case 'even':
                        return this.selectedDice % 2 === 0;
                    case 'odd':
                        return this.selectedDice % 2 === 1;
                }
            }
            throw new Error('invalid type');
        },
    },
    methods: {
        dropDice() {
            if (!this.acceptsSelectedDice) {
                return;
            }

            this.$emit('accept');
        }
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.slot {
    border: 2px dotted #140601;
    width: 88px;
    height: 88px;
    border-radius: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #140601;
    &.accepts {
        cursor: pointer;
    }
    > div {
        text-align: center;
    }
    .countdown {
        font-size: 300%;
    }
    .simple {
        display: inline-flex;
        div {
            margin: 4px;
        }
    }
}
</style>
