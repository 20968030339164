<template>
    <cc-frame-big :headline="$t('menu.home')">
        <cc-tabs>
            <cc-tab
                :route="{ name: 'overview' }"
                :label="$t('general.overview')"
            />
            <cc-tab
                :route="{ name: 'friendship_requests' }"
                :label="$t('friends.friendship_requests')"
            />
            <cc-tab
                :route="{ name: 'friends' }"
                :label="$t('friends.friends')"
            />
            <cc-tab
                :route="{ name: 'gifts' }"
                :label="$t('friends.gifts')"
            />
            <cc-tab
                :route="{ name: 'logs' }"
                :label="$t('log')"
            />
        </cc-tabs>

        <router-view />
    </cc-frame-big>
</template>

<script>
import Tabs from '../component/Tabs.vue';
import Tab from '../component/Tab.vue';
import FrameBig from "../component/FrameBig";

export default {
    components: {
        'cc-tabs': Tabs,
        'cc-tab': Tab,
        'cc-frame-big': FrameBig,
    },
}
</script>
