<template>
    <div
        class="avatar"
        :style="{ backgroundImage: image }"
    />
</template>

<script>
export default {
    props: {
        gender: {
            required: true,
            type: String,
        },
        type: {
            required: true,
            type: String,
        },
        side: {
            required: false,
            type: String,
            default: "left",
        }
    },
    computed: {
        image() {
            let image = require('../../images/avatar/full_' + this.side + '/' + this.gender + '/' + this.type + '.jpg');

            return 'url(' + image + ')';
        }
    }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.avatar {
    width: 200px;
    height: 262px;
}
</style>
