<template>
    <div class="payment_method">
        <img :src="'https://www.micropayment.de/resources/?what=img&group=' + imageGroup + '&show=type-j.1'" />
        <cc-buttonbar class="mb1 mt1">
            <cc-button @click="payment.buy($store.state.user.id, productId, baseUrl)">
                {{ $t('payment.buy') }}
            </cc-button>
        </cc-buttonbar>
    </div>
</template>

<script>
import Payment from "../model/Payment";

import Button from "./Button.vue";
import Buttonbar from "./Buttonbar.vue";

export default {
    components: {
        'cc-buttonbar': Buttonbar,
        'cc-button': Button,
    },
    props: {
        productId: {
            required: true,
            type: String,
        },
        imageGroup: {
            required: true,
            type: String,
        },
        baseUrl: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            payment: new Payment(),
        }
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.payment_method {
    color: $color-text-light;
    background-color: #262825;
    border: 4px solid transparent;
    border-image: url("../../images/frame_payment_method.png") 4 repeat;
}
</style>
