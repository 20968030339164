<template>
    <div
        class="creeper"
        :style="{ backgroundImage }"
    />
</template>

<script>
export default {
    props: {
        name: {
            required: true,
            type: String,
        },
        image: {
            required: true,
            type: String,
        },
        frequency: {
            required: true,
            type: String,
        }
    },
    computed: {
        backgroundImage() {
            return 'url(' + require('../../images/creeper/battle/' + this.frequency + '/' + this.image) + ')';
        }
    }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.creeper {
    width: 200px;
    height: 262px;
}
</style>
