<template>
    <div class="types">
        <div
            class="type damage"
            :class="{ active: damage }"
            :style="{ background: imageDamage }"
            :title="$t('card_type.damage')"
        />
        <div
            class="type shield"
            :class="{ active: shield }"
            :style="{ background: imageShield }"
            :title="$t('card_type.shield')"
        />
        <div
            class="type health"
            :class="{ active: health }"
            :style="{ background: imageHealth }"
            :title="$t('card_type.health')"
        />
        <div
            class="type diceModifier"
            :class="{ active: diceModifier }"
            :style="{ background: imageDiceModifier }"
            :title="$t('card_type.diceModifier')"
        />
    </div>
</template>

<script>
export default {
    props: {
        damage: {
            type: Boolean,
            required: true,
        },
        shield: {
            type: Boolean,
            required: true,
        },
        health: {
            type: Boolean,
            required: true,
        },
        diceModifier: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        imageDamage() {
            return 'url(' + require('../../images/card/type/damage.png') + ')';
        },
        imageShield() {
            return 'url(' + require('../../images/card/type/shield.png') + ')';
        },
        imageHealth() {
            return 'url(' + require('../../images/card/type/health.png') + ')';
        },
        imageDiceModifier() {
            return 'url(' + require('../../images/card/type/dice_modifier.png') + ')';
        },
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.types {
    .type {
        display: none;
        width: 38px;
        height: 38px;
        &.active {
            display: block;
        }
    }
}
</style>
