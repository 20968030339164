<template>
    <div style="position: relative">
        <v-navigation-drawer
            :value="value"
            app
            clipped
            hide-overlay
            floating
            :width="234"
            @input="$emit('input', $event)"
        >
            <div
                class="toggle"
                :style="toggleStyles"
            />
            <slot />
        </v-navigation-drawer>
        <div
            class="toggle"
            :style="toggleStyles"
            @click="$emit('input', !value)"
        />
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: false,
            default: null,
        },
    },
    computed: {
        toggleStyles() {
            const appBarOffset = this.$vuetify.breakpoint.width < 1264 ? 0 : 84;
            return {
                backgroundImage: this.value ? 'url(' + require('../../images/navigation_drawer_hide.png') + ')' : 'url(' + require('../../images/navigation_drawer_show.png') + ')',
                top: (appBarOffset + 40 + 167) + 'px',
                left: this.value ? '232px' : '0',
            }
        }
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.toggle {
    position: fixed;
    width: 35px;
    height: 91px;
    z-index: 1;
    cursor: pointer;
    &:hover {
        background-position-y: -91px;
    }
    &:active {
        background-position-y: -182px;
    }
}

.v-navigation-drawer {
    background: url('../../images/navigation_drawer.png') no-repeat;
    margin-top: 40px;
}
</style>
