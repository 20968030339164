<template>
    <router-link
        :to="route"
        class="tab"
    >
        {{ label }}
    </router-link>
</template>

<script>
export default {
    props: {
        route: {
            required: true,
            type: Object,
        },
        label: {
            required: true,
            type: String,
        }
    }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.tab {
    background-image: url("../../images/tabs.png");
    width: 100px;
    height: 30px;
    padding: 3px 8px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $color-text-light !important;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    &:hover, &.router-link-active {
        background-position-y: -30px;
        color: $button-text-color !important;
    }
}
</style>
