<template>
    <div>
        <cc-loading v-if="loading" />
        <div v-else>
            <div class="friendlist">
                <table>
                    <tbody>
                        <tr
                            v-for="(friendshipRequest, index) in friendshipRequests"
                            :key="index"
                        >
                            <td>
                                <cc-avatar-portrait
                                    :gender="friendshipRequest.user.avatarGender"
                                    :type="friendshipRequest.user.avatarType"
                                />
                            </td>
                            <td>
                                <div class="username">
                                    <router-link
                                        :to="{ name: 'profile', params: { id: friendshipRequest.user.id }}"
                                        class="user"
                                    >
                                        {{ friendshipRequest.user.username }}
                                    </router-link>
                                </div>
                                <div class="level">
                                    {{ $t('general.level') }} {{ friendshipRequest.user.level }}
                                </div>
                            </td>
                            <td class="right">
                                <cc-button @click="accept(friendshipRequest)">
                                    {{ $t('friends.invite.accept') }}
                                </cc-button>
                                <cc-button @click="deny(friendshipRequest)">
                                    {{ $t('friends.invite.deny') }}
                                </cc-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '../../component/Button.vue';
import Loading from '../../component/Loading.vue';
import AvatarPortrait from '../../component/AvatarPortrait.vue';

export default {
    components: {
        'cc-button': Button,
        'cc-avatar-portrait': AvatarPortrait,
        'cc-loading': Loading,
    },
    data() {
        return {
            friendshipRequests: [],
            loading: true,
        }
    },
    created() {
        this.load();
    },
    methods: {
        accept(friendshipRequest) {
            this.loading = true;
            this.$http
                .post('/friendships', { id: friendshipRequest.id })
                .then(() => this.load());
        },
        deny(friendshipRequest) {
            this.loading = true;
            this.$http
                .delete('friendship-requests/' + friendshipRequest.id)
                .then(() => this.load())
        },
        load() {
            this.$http
                .get('/friendship-requests')
                .then(response => {
                    this.friendshipRequests = response.data.data;
                    this.loading = false;
                });
        },
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../../css/variables";

.friendlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
}
table {
    tr:not(:last-of-type) {
        td {
            border-bottom: 1px solid $color-line-seperator;
        }
    }
}
.avatar {
    margin: 0 auto;
}
.username {
    font-size: 21px;
}
.level {
    font-size: 13px;
}
</style>
