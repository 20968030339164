<template>
    <v-app>
        <div class="loading-container">
            <cc-logo />
            <div class="mt1">
                {{ $t('general.game_loading') }}
            </div>
        </div>
    </v-app>
</template>

<script>
import Logo from './Logo.vue';

export default {
    components: {
        'cc-logo': Logo,
    }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: $color-text-light;
    font-size: 28px;
}
</style>
