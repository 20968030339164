import axios from 'axios';

export default class TreasureRepository {
    static list() {
        return new Promise(resolve => {
            axios
                .get('/treasures')
                .then(response => resolve(response.data.data));
        });
    }

    static payin(amount) {
        return new Promise(resolve => {
            axios.post('/treasures/payin', { amount }).then(() => resolve());
        });
    }

    static payout(amount) {
        return new Promise(resolve => {
            axios.post('/treasures/payout', { amount }).then(() => resolve());
        });
    }

    static sign(id) {
        return new Promise(resolve => {
            axios.post('/treasures/' + id + '/sign').then(() => resolve());
        });
    }

    static dismiss(id) {
        return new Promise(resolve => {
            axios.post('/treasures/' + id + '/dismiss').then(() => resolve());
        });
    }
}
