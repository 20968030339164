import Vue from 'vue';
import VueRouter from 'vue-router';

import Landingpage from './page/Landingpage.vue';
import Login from './page/Landingpage/Login.vue';
import Signup from './page/Landingpage/Signup.vue';
import Home from './page/Home.vue';
import HomeOverview from './page/Home/Overview.vue';
import HomeFriendshipRequests from './page/Home/FriendshipRequests.vue';
import HomeFriends from './page/Home/Friends.vue';
import HomeGifts from './page/Home/Gifts.vue';
import HomeLogs from './page/Home/Logs.vue';
import Creepers from './page/Creepers.vue';
import Skills from './page/Skills.vue';
import SkillsInventory from './page/Skills/Inventory.vue';
import SkillsLimits from './page/Skills/Limits.vue';
import Ranking from './page/Ranking.vue';
import RankingMelee from './page/Ranking/Melee.vue';
import RankingGold from './page/Ranking/Gold.vue';
import Treasure from './page/Treasure.vue';
import TreasureTreasure from './page/Treasure/Treasure.vue';
import TreasureMagicTreasure from './page/Treasure/MagicTreasure.vue';
import Premium from './page/Premium.vue';
import PremiumBase from './page/Premium/Base.vue';
import PremiumMoney from './page/Premium/Money.vue';
import PremiumMelee from './page/Premium/Melee.vue';
import PremiumEnergy from './page/Premium/Energy.vue';
import PremiumSkills from './page/Premium/Skills.vue';
import Diamonds from './page/Diamonds.vue';
import PaymentMethods from './page/PaymentMethods.vue';
import Matchup from './page/Matchup.vue';
import Fight from './page/Fight.vue';
import Profile from './page/Profile.vue';
import authentication from "./authentication";

Vue.use(VueRouter);

const checkLogin = (to, from, next) => {
    if (authentication.isAuthenticated()) {
        next({ name: 'overview' });
    } else {
        window.eventbus.$emit('logout');
        next();
    }
};

const routes = [
    { path: '/', component: Landingpage, children: [
        { path: '', redirect: { name: 'login' } },
        { path: 'login', name: 'login', component: Login, beforeEnter: checkLogin},
        { path: 'signup', name: 'signup', component: Signup, beforeEnter: checkLogin },
    ]},
    { path: '/home', component: Home, children: [
        { path: 'overview', name: 'overview', component: HomeOverview },
        { path: 'friendship-requests', name: 'friendship_requests', component: HomeFriendshipRequests },
        { path: 'friends', name: 'friends', component: HomeFriends },
        { path: 'gifts', name: 'gifts', component: HomeGifts },
        { path: 'logs', name: 'logs', component: HomeLogs },
    ]},
    { path: '/creepers', name: 'creepers', component: Creepers },
    { path: '/skills', component: Skills, children: [
        { path: 'inventory', name: 'skills_inventory', component: SkillsInventory },
        { path: 'limits', name: 'skills_limits', component: SkillsLimits },
    ]},
    { path: '/ranking', component: Ranking, children: [
        { path: 'melee', name: 'ranking_melee', component: RankingMelee },
        { path: 'gold', name: 'ranking_gold', component: RankingGold },
    ]},
    { path: '/treasure', component: Treasure, children: [
        { path: 'treasure', name: 'treasure_treasure', component: TreasureTreasure },
        { path: 'magic-treasure', name: 'treasure_magic_treasure', component: TreasureMagicTreasure },
    ]},
    { path: '/premium', component: Premium, children: [
        { path: 'base', name: 'premium_base', component: PremiumBase },
        { path: 'money', name: 'premium_money', component: PremiumMoney },
        { path: 'melee', name: 'premium_melee', component: PremiumMelee },
        { path: 'energy', name: 'premium_energy', component: PremiumEnergy },
        { path: 'skills', name: 'premium_skills', component: PremiumSkills },
    ]},
    { path: '/diamonds', name: 'diamonds', component: Diamonds },
    { path: '/payment-methods/:id', name: 'payment_methods', component: PaymentMethods, props: true },
    { path: '/matchup/:type/:id', name: 'matchup', component: Matchup, props: true },
    { path: '/fight/:id', name: 'fight', component: Fight, props: true },
    { path: '/profile/:id', name: 'profile', component: Profile, props: true },
];

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    if (['login', 'signup'].indexOf(to.name) === -1 && !authentication.isAuthenticated()) {
        next({ name: 'login' });
    } else {
        next();
    }
});

export default router;
