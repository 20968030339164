import axios from 'axios';

export default class VisibleCellRepository {

    static findAll() {
        return new Promise(resolve => {
            axios
                .get('/visible-cells')
                .then(response => resolve(response.data.data));
        });
    }
}
