<template>
    <cc-side-box
        :headline="$t('general.task') + ' ' + task.order"
        :intro="$t('task.title.' + task.description)"
    >
        <div>{{ $t('info.reward') }}</div>
        <div class="task_reward">
            <cc-icon
                v-if="task.gold > 0"
                type="gold"
                :description="$t('gold')"
            >
                {{ task.gold }}
            </cc-icon>
            <cc-icon
                v-if="task.experience > 0"
                type="experience"
                :description="$t('experience')"
            >
                {{ task.experience }}
            </cc-icon>
            <cc-icon
                v-if="task.diamonds > 0"
                type="diamond"
                :description="$t('diamond')"
            >
                {{ task.diamonds }}
            </cc-icon>
        </div>
        <cc-buttonbar
            v-if="task.isFinished"
            class="mt1"
        >
            <cc-button
                :glowing="true"
                @click="finish()"
            >
                {{ $t('button.finish') }}
            </cc-button>
        </cc-buttonbar>
    </cc-side-box>
</template>

<script>
import TaskLayer from "../model/dto/layer/TaskLayer";
import UserRepository from "../repository/UserRepository";


import Button from './Button.vue';
import Buttonbar from './Buttonbar.vue';
import Icon from './Icon.vue';
import SideBox from './SideBox.vue';

export default {
    components: {
        'cc-button': Button,
        'cc-buttonbar': Buttonbar,
        'cc-icon': Icon,
        'cc-side-box': SideBox,
    },
    computed: {
        task() {
            return this.$store.state.user.task;
        },
    },
    methods: {
        finish() {
            this.$http
                .post('/task')
                .then(() => {
                    this.$store.commit('addLayer', new TaskLayer(this.task.gold, this.task.experience, this.task.diamonds));

                    return UserRepository.findMe();
                })
                .then((user) => this.$store.commit('loadUser', user));
        }
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.task_reward {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
</style>
