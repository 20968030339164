import authentication from "./authentication";

require('./axios');

import '../css/app.scss';

import Vue from 'vue';

import router from './router';
import store from './store';
import i18n from './i18n';
import vuetify from "./vuetify";
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

import GameLoading from './component/GameLoading.vue';
import Game from './component/Game.vue';

window.eventbus = new Vue();

Sentry.init({
    dsn: 'https://12bb1f9c7d3b4482a6c80a3bb42dd124@o406800.ingest.sentry.io/5275005',
    integrations: [new VueIntegration({ Vue, attachProps: true, logError: true })],
    environment: process.env.NODE_ENV,
})

window.vue = new Vue({
    el: '#app',
    store,
    router,
    i18n,
    vuetify,
    components: {
        'cc-game-loading': GameLoading,
        'cc-game': Game,
    },
    created() {
        window.eventbus.$on('api_returned_401', () => window.eventbus.$emit('api_request_failed'));
        window.eventbus.$on('api_returned_403', () => window.eventbus.$emit('api_request_failed'));
        window.eventbus.$on('api_request_failed', () => {
            window.eventbus.$emit('logout');
            if (this.$router.currentRoute.name !== 'login') {
                this.$router.push({ name: 'login' });
            }
        });
        window.eventbus.$on('logout', () => {
            this.$store.commit('logout');
            authentication.reset();
            authentication.updateAxios();
        });
        window.eventbus.$on('start_game', () => {
            this.$store.commit('markLoading');
            this.$store.dispatch('loadUser');
            setInterval(() => this.$store.dispatch('tick'), 1000);
        });
        if (authentication.isAuthenticated()) {
            window.eventbus.$emit('start_game');
        } else {
            this.$store.commit('markLoaded');
        }
    },
});

Vue.directive('click-outside', {
    bind(el, binding, vnode) {
        el.event = event => {
            if (!(el === event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.event);
    },
    unbind(el) {
        document.body.removeEventListener('click', el.event);
    }
});
