<template>
    <cc-frame-big :headline="$t('profile.title')">
        <cc-loading v-if="loading" />
        <div v-else>
            <h1>{{ user.username }}</h1>
            <cc-avatar
                :gender="user.avatarGender"
                :type="user.avatarType"
            />
            <cc-button
                v-if="!user.isFriend && !user.hasFriendshipRequest"
                @click="requestFriendship()"
            >
                {{ $t('profile.request_friendship') }}
            </cc-button>
        </div>
    </cc-frame-big>
</template>

<script>
import Avatar from '../component/Avatar.vue';
import Button from '../component/Button.vue';
import Loading from '../component/Loading.vue';
import FrameBig from "../component/FrameBig";

export default {
    components: {
        'cc-frame-big': FrameBig,
        'cc-avatar': Avatar,
        'cc-button': Button,
        'cc-loading': Loading,
    },
    props: {
        id: {
            required: true,
            type: String,
        }
    },
    data() {
        return {
            user: {},
            loading: true,
        }
    },
    watch: {
        id() {
            this.load();
        }
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            this.loading = true;
            this.$http
                .get('/users/' + this.id)
                .then(response => {
                    this.user = response.data.data;
                    this.loading = false;
                })
        },
        requestFriendship() {
            this.loading = true;
            this.$http
                .post('/friendship-requests', { userId: this.$store.state.user.id, requestedUserId: this.user.id })
                .then(this.load());
        },
    },
}
</script>
