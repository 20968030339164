import axios from 'axios';

export default class MeleeRepository {
    static find(id) {
        return new Promise(resolve => {
            axios
                .get('/melees/' + id)
                .then(response => resolve(response.data.data));
        });
    }

    static createAction(id, cardId, diceIds) {
        return new Promise(resolve => {
            axios.post('/melees/' + id + '/actions', {
                'cardId': cardId,
                'fighterDiceIds': diceIds,
            }).then(() => resolve());
        });
    }

    static createTurn(id) {
        return new Promise(resolve => {
            axios.post('/melees/' + id + '/turns').then(() => resolve());
        });
    }

    static extinguishDice(id, diceId) {
        return new Promise(resolve => {
            axios.post('/melees/' + id + '/burning-dice', {
                'fighterDiceId': diceId,
            }).then(() => resolve());
        });
    }

    static unshockCard(id, cardId, diceId) {
        return new Promise(resolve => {
            axios.post('/melees/' + id + '/shocked-card', {
                'cardId': cardId,
                'fighterDiceId': diceId,
            }).then(() => resolve());
        });
    }
}
