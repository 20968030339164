<template>
    <cc-layer :headline="headline">
        <p class="message">
            {{ message }}
        </p>
        <cc-button @click="close()">
            {{ $t('button.ok') }}
        </cc-button>
    </cc-layer>
</template>

<script>
import Button from '../../component/Button.vue';
import Layer from '../../component/Layer.vue';

export default {
    components: {
        'cc-button': Button,
        'cc-layer': Layer,
    },
    props: {
        headline: {
            required: true,
            type: String,
        },
        message: {
            required: true,
            type: String,
        }
    },
    methods: {
        close() {
            this.$store.commit('removeLayer', this);
        }
    }
}
</script>
