<template>
    <div>
        <cc-loading v-if="loading" />
        <div v-else>
            <div class="friendlist">
                <table>
                    <tbody>
                        <tr
                            v-for="(friend, index) in friends"
                            :key="index"
                        >
                            <td>
                                <cc-avatar-portrait
                                    :gender="friend.avatarGender"
                                    :type="friend.avatarType"
                                />
                            </td>
                            <td>
                                <div class="username">
                                    <router-link
                                        :to="{ name: 'profile', params: { id: friend.id }}"
                                        class="user"
                                    >
                                        {{ friend.username }}
                                    </router-link>
                                </div>
                                <div class="level">
                                    {{ $t('general.level') }} {{ friend.level }}
                                </div>
                            </td>
                            <td class="right">
                                <cc-button
                                    v-if="!friend.hasPendingGift"
                                    @click="sendGift(friend)"
                                >
                                    10
                                    <img
                                        src="../../../images/icon/energy.png"
                                        :alt="$t('energy')"
                                        style="vertical-align: text-top; margin-top: 1px; width: 20px"
                                    />
                                </cc-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import FriendRepository from "../../repository/FriendRepository";

import Button from '../../component/Button.vue';
import Loading from '../../component/Loading.vue';
import AvatarPortrait from '../../component/AvatarPortrait.vue';

export default {
    components: {
        'cc-button': Button,
        'cc-avatar-portrait': AvatarPortrait,
        'cc-loading': Loading,
    },
    data() {
        return {
            friends: [],
            loading: true,
        }
    },
    created() {
        FriendRepository.findAll().then(friends => {
            this.friends = friends;
            this.loading = false;
        });
    },
    methods: {
        sendGift(friend) {
            friend.hasPendingGift = true;
            this.$http.post('/users/' + friend.id + '/gifts');
        },
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../../css/variables";

.friendlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
}
table {
    tr:not(:last-of-type) {
        td {
            border-bottom: 1px solid $color-line-seperator;
        }
    }
}
.avatar {
    margin: 0 auto;
}
.username {
    font-size: 21px;
}
.level {
    font-size: 13px;
}
</style>
