import CellParser from "../Parser/CellParser";
import mapData from "../data/map.json";
import VisibleCellRepository from "../../js/repository/VisibleCellRepository";

export default class CellRepository {

    static refresh() {
        return new Promise(resolve => {
            VisibleCellRepository.findAll().then(data => {
                const cells = new CellParser().parse(mapData);
                const visibleCellIds = data.map(cell => cell.id);
                cells
                    .filter(cell => visibleCellIds.indexOf(cell.id) !== -1)
                    .forEach(cell => cell.makeVisible());
                resolve(cells);
            });
        });
    }

    static findAll() {
        return CellRepository.promise;
    }

    static findById(id) {
        return new Promise(resolve => {
            CellRepository.promise.then(cells => resolve(cells.filter(cell => cell.id === id)[0]));
        });
    }

    static findByCoordinate(coordinate) {
        return new Promise(resolve => {
            CellRepository.promise.then(cells => resolve(cells.filter(cell => cell.contains(coordinate))[0]));
        })
    }

    static findVisible() {
        return new Promise(resolve => {
            return CellRepository.promise.then(cells => resolve(cells.filter(cell => cell.visible)));
        })
    }

    static findNotVisible() {
        return new Promise(resolve => {
            return CellRepository.promise.then(cells => resolve(cells.filter(cell => !cell.visible)));
        })
    }
}
CellRepository.promise = CellRepository.refresh();
