export default class RouteDrawer {

    constructor(context) {
        this.context = context;
    }

    draw(route) {
        const coordinates = route.line;
        this.context.beginPath();
        this.context.moveTo(coordinates[0]);
        coordinates.forEach(coordinate => this.context.lineTo(coordinate));
        this.context.stroke(route.getColor(), route.getWidth(), [2 / 3, 2 / 3]);
        this.context.closePath();
    }
}
