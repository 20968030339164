<template>
    <div class="ml-3">
        <cc-loading v-if="loading" />
        <div v-else>
            <div>{{ $t('biome.' + biome) }}</div>
            <div v-if="cell.id !== $store.state.user.cell">
                Distanz: {{ distance }}
            </div>
            <div v-else>
                Du bist hier
            </div>
            <div style="display: flex">
                <cc-creeper-battle
                    v-for="(creeper, index) in creepers"
                    :key="index"
                    :name="creeper.name"
                    :frequency="creeper.frequency"
                    :image="creeper.image"
                    class="mr-3"
                    style="width: 100px; height: 131px; background-size: 100%"
                />
            </div>
            <cc-button
                v-if="cell.id !== $store.state.user.cell"
                class="mt-3"
                @click="move()"
            >
                bewegen
            </cc-button>
        </div>
    </div>
</template>

<script>
import Cell from "../../map/Cell";
import axios from "../axios";
import Button from "./Button";
import CreeperBattle from "./CreeperBattle";
import Loading from "./Loading";

export default {
    components: {
        'cc-button': Button,
        'cc-creeper-battle': CreeperBattle,
        'cc-loading': Loading,
    },
    props: {
        cell: {
            type: Cell,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            biome: '',
            distance: 0,
            creepers: [],
        }
    },
    watch: {
        cell() {
            this.loading = true;
            this.load();
        },
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            axios
                .get('/cells/' + this.cell.id)
                .then(response => {
                    this.loading = false;
                    this.biome = response.data.data.biome;
                    this.distance = response.data.data.distance;
                    this.creepers = response.data.data.creepers;
                });
        },
        move() {
            this.$emit('move');
        },
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
