<template>
    <cc-frame-big :headline="$t('menu.skills')">
        <cc-tabs>
            <cc-tab
                :route="{ name: 'skills_inventory' }"
                :label="$t('skills.inventory.name')"
            />
            <cc-tab
                :route="{ name: 'skills_limits' }"
                :label="$t('skills.limits.name')"
            />
        </cc-tabs>

        <router-view />
    </cc-frame-big>
</template>

<script>
import Tabs from '../component/Tabs.vue';
import Tab from '../component/Tab.vue';
import FrameBig from "../component/FrameBig";

export default {
    components: {
        'cc-frame-big': FrameBig,
        'cc-tabs': Tabs,
        'cc-tab': Tab,
    },
}
</script>
