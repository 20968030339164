import Biome from "../Biome";
import Coordinate from "../Coordinate";
import Cell from "../Cell";

export default class CellParser {

    parse(json) {
        return json.map(cell => {
            return new Cell(
                cell.id,
                cell.type,
                new Biome(cell.biome),
                cell.polygon.map(coordinate => new Coordinate(coordinate[0], -coordinate[1])),
                cell.creepers,
            );
        });
    }
}
