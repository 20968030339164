import Coordinate from "../Coordinate";
import Route from "../Route";

export default class RouteParser {

    parse(json) {
        return json.features.map(feature => {
            return new Route(
                feature.properties.type,
                feature.geometry.coordinates.map(coordinate => new Coordinate(coordinate[0], -coordinate[1])),
            );
        });
    }
}
