<template>
    <div>
        <cc-levelup-layer
            v-for="layer in $store.getters.levelupLayers"
            :id="layer.id"
            :key="layer.id"
            :level="layer.level"
            :gold="layer.gold"
            :experience="layer.experience"
            :limitpoints="layer.limitpoints"
            :features="layer.features"
        />
        <cc-found-creeper-layer
            v-for="layer in $store.getters.foundCreeperLayers"
            :id="layer.id"
            :key="layer.id"
            :creeper="layer.creeper"
            :melee-id="layer.meleeId"
        />
        <cc-book-creeper-layer
            v-for="(layer, index) in $store.getters.bookCreeperLayers"
            :key="index"
            :creeper="layer.creeper"
        />
        <cc-chase-layer
            v-for="layer in $store.getters.chaseLayers"
            :id="layer.id"
            :key="layer.id"
            :creeper-name="layer.creeperName"
            :creeper-image="layer.creeperImage"
            :creeper-frequency="layer.creeperFrequency"
            :card-definition="layer.cardDefinition"
        />
        <cc-task-layer
            v-for="(layer, index) in $store.getters.taskLayers"
            :key="index"
            :gold="layer.gold"
            :experience="layer.experience"
            :diamonds="layer.diamonds"
        />
        <cc-error-layer
            v-for="(layer, index) in $store.getters.errorLayers"
            :key="index"
            :messages="layer.messages"
        />
        <cc-buy-layer
            v-for="(layer, index) in $store.getters.buyLayers"
            :key="index"
            :headline="layer.headline"
            :message="layer.message"
            :callback="layer.callback"
        />
        <cc-message-layer
            v-for="(layer, index) in $store.getters.messageLayers"
            :key="index"
            :headline="layer.headline"
            :message="layer.message"
        />
    </div>
</template>

<script>
import MessageLayer from './layer/MessageLayer.vue';
import BuyLayer from './layer/BuyLayer.vue';
import ErrorLayer from './layer/ErrorLayer.vue';
import TaskLayer from './layer/TaskLayer.vue';
import FoundCreeperLayer from './layer/FoundCreeperLayer.vue';
import BookCreeperLayer from './layer/BookCreeperLayer.vue';
import ChaseLayer from './layer/ChaseLayer.vue';
import LevelupLayer from './layer/LevelupLayer.vue';

export default {
    components: {
        'cc-message-layer': MessageLayer,
        'cc-buy-layer': BuyLayer,
        'cc-error-layer': ErrorLayer,
        'cc-task-layer': TaskLayer,
        'cc-found-creeper-layer': FoundCreeperLayer,
        'cc-book-creeper-layer': BookCreeperLayer,
        'cc-chase-layer': ChaseLayer,
        'cc-levelup-layer': LevelupLayer,
    }
}
</script>
