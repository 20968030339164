export default class Route {

    constructor(type, line) {
        this.type = type;
        this.line = line;
    }

    getColor() {
        switch (this.type) {
            case 'roads':
                return 'rgba(208, 99, 36, 229)';

            case 'trails':
                return 'rgba(208, 99, 36, 229)';

            case 'searoutes':
                return 'rgba(255, 255, 255, 204)';
        }

        throw new Error('unknown route type: ' + this.type);
    }

    getWidth() {
        switch (this.type) {
            case 'roads':
                return 0.7 / 3;

            case 'trails':
                return 0.25 / 3;

            case 'searoutes':
                return 0.45 / 3;
        }

        throw new Error('unknown route type: ' + this.type);
    }
}
