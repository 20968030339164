import axios from "axios";

export class Authentication
{
    setApiKey(apiKey)
    {
        window.localStorage.setItem('castlecreepers.api_key', apiKey);
        this.updateAxios();
    }

    getApiKey()
    {
        return this.load();
    }

    updateAxios()
    {
        axios.defaults.headers.common['X-AUTH-TOKEN'] = this.isAuthenticated() ? 'Bearer ' + this.load() : undefined;
    }

    isAuthenticated()
    {
        return this.load() !== null;
    }

    reset()
    {
        window.localStorage.removeItem('castlecreepers.api_key');
    }

    load()
    {
        return window.localStorage.getItem('castlecreepers.api_key');
    }
}

const authentication = new Authentication();

export default authentication;
