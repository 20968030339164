<template>
    <v-list>
        <v-list-item :to="{ name: 'overview' }">
            <v-list-item-icon>
                <i class="flaticon-menu-home" />
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('menu.home') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'creepers' }">
            <v-list-item-icon>
                <i class="flaticon-menu-creepers" />
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('menu.creepers') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'skills_inventory' }">
            <v-list-item-icon>
                <i class="flaticon-menu-skills" />
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('menu.skills') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'ranking_melee' }">
            <v-list-item-icon>
                <i class="flaticon-menu-ranking" />
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('menu.ranking') }}</v-list-item-title>
            </v-list-item-content>
            <cc-disabled :level="3" />
        </v-list-item>
        <v-list-item :to="{ name: 'treasure_treasure' } ">
            <v-list-item-icon>
                <i class="flaticon-menu-treasure" />
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('menu.treasure') }}</v-list-item-title>
            </v-list-item-content>
            <cc-disabled :level="5" />
        </v-list-item>
        <v-list-item :to="{ name: 'premium_base' }">
            <v-list-item-icon>
                <i class="flaticon-menu-premium" />
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('menu.premium') }}</v-list-item-title>
            </v-list-item-content>
            <cc-disabled :level="6" />
        </v-list-item>
    </v-list>
</template>

<script>
import Disabled from './Disabled.vue';

export default {
    components: {
        'cc-disabled': Disabled,
    }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.v-list {
    margin-top: 2px;

}
.v-list-item {
    width: 150px;
    height: 52px;
    position: relative;
    margin-left: 50px;
    margin-top: 12px;
    background-image: url("../../images/main_menu_item.png");
    text-decoration: none;
    &:hover {
        background-position: 0 -52px;
    }
    &:active {
        background-position: 0 -104px;
    }
    .v-list-item__title {
        margin-left: 44px;
        color: $color-text-light;
        margin-top: -4px;
        text-align: center;
    }
    .v-list-item__icon {
        position: absolute;
        color: #395720;
        font-size: 1.5rem;
        margin-top: 7px;
        margin-left: -2px;
    }
}
.v-list-item--active:before, .v-list-item--active:before {
    opacity: 0 !important;
}
</style>
