<template>
    <div class="background">
        <div
            v-click-outside="close"
            class="book"
        >
            <cc-loading v-if="loading" />
            <div v-else>
                <div class="content">
                    <div class="page">
                        <div class="arrow">
                            <cc-arrow-previous
                                v-show="page > 1"
                                @click="page--"
                            />
                        </div>
                        <div class="creepers">
                            <div
                                v-for="(creeper, index) in creepersLeft"
                                :key="creeper.id"
                                class="mb1"
                                style="position: relative"
                            >
                                <cc-creeper
                                    :name="creeper.name"
                                    :image="creeper.image"
                                    :found="creeper.found"
                                    :frequency="creeper.frequency"
                                    :style="{ cursor: creeper.found ? 'pointer' : 'default' }"
                                    @click.native="showCreeper(creeper)"
                                />
                                <div
                                    v-if="!creeper.found"
                                    style="position: absolute; bottom: 4px; right: 6px; font-weight: bold;"
                                >
                                    {{ creeperNumber(index, 0) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="page">
                        <div class="creepers">
                            <div
                                v-for="(creeper, index) in creepersRight"
                                :key="creeper.id"
                                class="mb1"
                                style="position: relative"
                            >
                                <cc-creeper
                                    :name="creeper.name"
                                    :image="creeper.image"
                                    :found="creeper.found"
                                    :frequency="creeper.frequency"
                                    :style="{ cursor: creeper.found ? 'pointer' : 'default' }"
                                    @click.native="showCreeper(creeper)"
                                />
                                <div
                                    v-if="!creeper.found"
                                    style="position: absolute; bottom: 4px; right: 6px; font-weight: bold;"
                                >
                                    {{ creeperNumber(index, 1) }}
                                </div>
                            </div>
                        </div>
                        <div class="arrow">
                            <cc-arrow-next
                                v-show="page < Math.ceil(creepers.length / 24)"
                                @click="page++"
                            />
                        </div>
                    </div>
                </div>
                <div class="pages" />
            </div>
        </div>
    </div>
</template>

<script>
import Creeper from '../component/Creeper.vue';
import ArrowNext from '../component/ArrowNext.vue';
import ArrowPrevious from '../component/ArrowPrevious.vue';
import Loading from '../component/Loading.vue';

import CreeperRepository from "../repository/CreeperRepository";
import BookCreeperLayer from "../model/dto/layer/BookCreeperLayer";

export default {
    components: {
        'cc-arrow-next': ArrowNext,
        'cc-arrow-previous': ArrowPrevious,
        'cc-creeper': Creeper,
        'cc-loading': Loading,
    },
    data() {
        return {
            creepers: [],
            page: 1,
            loading: true,
        }
    },
    computed: {
        creepersLeft() {
            return this.creepers.slice((this.page - 1) * 24, (this.page - 1) * 24 + 12);
        },
        creepersRight() {
            return this.creepers.slice((this.page - 1) * 24 + 12, (this.page - 1) * 24 + 24);
        },
    },
    created() {
        this.reload();

        window.eventbus.$on('game_event', gameEvent => {
            if (gameEvent.name === 'chase_successful') {
                this.reload();
            }
        });
    },
    methods: {
        close() {
            this.$emit('close');
        },
        reload() {
            CreeperRepository.list().then(creepers => {
                this.creepers = creepers;
                this.loading = false;
            });
        },
        creeperNumber(index, offset) {
            return (this.page - 1) * 24 + index + offset * 12 + 1;
        },
        showCreeper(creeper) {
            if (!creeper.found) {
                return;
            }

            this.$store.commit('addLayer', new BookCreeperLayer(creeper));
        },
    },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "../../css/variables";

.background {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    .book {
        background-image: url("../../images/book.png");
        width: 960px;
        height: 732px;
        .content {
            margin: 38px 62px;
            height: 544px;
            display: flex;
            justify-content: space-between;
            .page {
                display: flex;
                align-items: stretch;
                .arrow {
                    width: 35px;
                    margin: auto;
                    > div {
                        margin: 0 auto;
                    }
                }
                .creepers {
                    display: flex;
                    flex-wrap: wrap;
                    width: 310px;
                    justify-content: space-between;
                    margin-bottom: auto;
                }
            }
        }
        .pages {
            display: flex;
            justify-content: space-between;
        }
    }
}
</style>
