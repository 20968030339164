<template>
    <div class="buttonbar">
        <slot />
    </div>
</template>

<script>
export default {
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.buttonbar {
    display: flex;
    justify-content: center;
}
</style>
